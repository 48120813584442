import React, { useState } from "react";
import Heading2 from "./Heading2";
import DialogBox from "./DialogBox";
import pescriptionFormat from "@/assets/pescription.svg";
import { Button } from "./ui/button";
import InputFile from "./InputFile";
import { useSelector } from "react-redux";
import InputField from "./InputField";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import AlertBox from "./AlertBox";
import doctor from "@/assets/doctor_2.jpg";
import AddPatient from "./AddPatient";
import { DialogClose } from "./ui/dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

function UploadPrescription({ doctor = false }) {
  const userData = useSelector((state) => state.auth?.userData);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [file, setFile] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function addPrescription() {
    const formData = new FormData();
    formData.append("prescriptionImg", file);
    formData.append(
      "patient",
      selectedPatient?.firstName + " " + selectedPatient.lastName
    );
    formData.append("dateOfIssue", selectDate);
    console.log(formData);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/prescription/upload`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully uploaded");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Upload failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  if (doctor === true) {
    return (
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>Upload Old Prescription</AccordionTrigger>
          <AccordionContent>
            <div className="w-full flex flex-col justify-center items-center mt-5 gap-5 overflow-auto">
              <img src={pescriptionFormat} className="w-[10rem]" alt="" />
              <p className="text-center font-semibold text-slate-500">
                Please upload your prescription, we will contact you shortly.
              </p>
              <div className="w-full">
                <p className="font-semibold">Patient Name</p>
                <section className="w-full flex justify-start mt-2 items-start gap-2">
                  {userData?.patient?.length === 0 && (
                    <section className="flex justify-between items-center gap-2">
                      {" "}
                      <img
                        src={pescriptionFormat}
                        className="w-[10rem]"
                        alt=""
                      />
                      <p>No Patient Found</p>
                      <AddPatient />
                    </section>
                  )}
                  {userData?.patient?.length > 0 &&
                    userData?.patient?.map((e, i) => (
                      <section
                        className={`border px-3 py-2 cursor-pointer ${
                          selectedPatient?.firstName === e?.firstName
                            ? "border-blue-500 border-2"
                            : ""
                        }`}
                        onClick={() => setSelectedPatient(e)}
                      >
                        <p>{e?.firstName}</p>
                      </section>
                    ))}
                </section>
              </div>
              <div className="w-full flex flex-col justify-start items-start gap-2">
                <InputField
                  label="Upload Prescription (PDF/image)"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <DialogClose className="flex justify-start w-full">
                <Button onClick={addPrescription}>Upload</Button>
              </DialogClose>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <div className="w-[91vw] flex justify-center sm:justify-center items-center px-3 gap-3">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[100%] bg-[#37B7C3] rounded-2xl">
        <div className="w-full h-full flex justify-between items-center flex-col md:flex-row layer p-5 rounded-2xl gap-5">
          <div className="text-center md:text-left flex justify-center items-center flex-wrap sm:justify-between w-full gap-3">
            <h2 className="text-[20px] md:text-[30px] font-semibold text-white">
              Upload Your Prescription
            </h2>
            <DialogBox
              title={"Upload Prescription"}
              buttonClass={"text-slate-700 shadow-lg"}
            >
              <div className="w-full flex flex-col justify-center items-center mt-5 gap-5 overflow-auto">
                <img src={pescriptionFormat} className="w-[10rem]" alt="" />
                <p className="text-center font-semibold text-slate-500">
                  Please upload your prescription, we will contact you shortly.
                </p>
                <div className="w-full">
                  <p className="font-semibold">Patient Name</p>
                  <section className="w-full flex justify-start mt-2 items-start gap-2">
                    {userData?.patient?.length === 0 && (
                      <section className="flex justify-between items-center gap-2">
                        {" "}
                        <img
                          src={pescriptionFormat}
                          className="w-[10rem]"
                          alt=""
                        />
                        <p>No Patient Found</p>
                        <AddPatient />
                      </section>
                    )}
                    {userData?.patient?.length > 0 &&
                      userData?.patient?.map((e, i) => (
                        <section
                          className={`border px-3 py-2 cursor-pointer ${
                            selectedPatient?.firstName === e?.firstName
                              ? "border-blue-500 border-2"
                              : ""
                          }`}
                          onClick={() => setSelectedPatient(e)}
                        >
                          <p>{e?.firstName}</p>
                        </section>
                      ))}
                  </section>
                </div>
                <div className="w-full flex flex-col justify-center items-center gap-2">
                  <InputField
                    label="Upload Prescription (PDF/image)"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <DialogClose>
                  <Button onClick={addPrescription}>Upload</Button>
                </DialogClose>
              </div>
            </DialogBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadPrescription;
