import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { Link, NavLink } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import InputField from "@/components/InputField";
import Loading from "@/components/Loading";
import { TfiReload } from "react-icons/tfi";
import { IoMdCloudDownload } from "react-icons/io";

function AllOrders() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/products/all?search=${search}&startDate=${startDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      setProductsData(allData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/generate/product/report`,
        method: "post",
        data: {
          startDate,
          endDate,
          data: productsData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Report generate failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload, startDate, endDate]);

  console.log(productsData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Orders History</Heading2>
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end items-center w-full mt-5 gap-3">
          <NavLink
            to={"/admin/due-orders"}
            className="font-semibold text-blue-500 hover:underline"
          >
            Pending Orders
          </NavLink>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <Table className="mt-10">
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Order Number</TableHead>
              <TableHead className="text-center">Products</TableHead>
              <TableHead className="text-center">Total Price</TableHead>
              <TableHead className="text-center">Coupon</TableHead>
              <TableHead className="text-center">Discount</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Delivary Status</TableHead>
              <TableHead className="text-center">Address</TableHead>
              <TableHead className="text-center">Pincode</TableHead>
              <TableHead className="text-center">Date of Order</TableHead>
              <TableHead className="text-center">Invoice</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.invoiceId}</TableCell>
                  <TableCell className="text-left">
                    <DialogBox
                      title={`${
                        e?.products?.length === 1
                          ? "1 Product"
                          : `${e?.products?.length} Products`
                      }`}
                      contentTitle={"Included Tests"}
                    >
                      {e?.products?.map((j, i) => (
                        <div
                          className="border flex justify-between px-3 py-3 rounded-md"
                          key={i}
                        >
                          <p>{j?.product?.title}</p>
                          <p>Rs. {j?.price}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.totalPrice}</TableCell>
                  <TableCell>
                    {e?.coupon?.length > 0 ? `${e?.coupon}` : "None"}
                  </TableCell>
                  <TableCell>
                    {e?.discount > 0 ? `${e?.discount}` : "0"}
                  </TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>{e?.delivaryStatus}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.address?.pincode}</TableCell>
                  <TableCell>{e?.date?.toString()?.split("T")[0]}</TableCell>
                  <TableCell>
                    {e?.invoice?.length > 0 || e?.invoice != undefined ? (
                      <Button asChild className="w-full" disabled>
                        <Link
                          to={`${SERVER_URL}/api/v1/fetch/image/${e?.invoice}`}
                          target="_blank"
                        >
                          Invoice
                        </Link>
                      </Button>
                    ) : (
                      <Button className="w-full" disabled>
                        <Link
                          to={`${SERVER_URL}/api/v1/fetch/image/${e?.invoice}`}
                          target="_blank"
                        >
                          Invoice
                        </Link>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AllOrders;
