import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productCart: 0,
    medicineCart: 0,
    labCart: 0,
}

const cartNumber = createSlice({
    name: "cartNumber",
    initialState,
    reducers: {
        increaseProductNumber: (state) => {
            state.productCart += 1
        },
        increaseMedicineNumber: (state) => {
            state.medicineCart += 1
        },
        increaseLabNumber: (state) => {
            state.labCart += 1
        },
        resetProductNumber: (state) => {
            state.productCart = 0
        },
        resetMedicineNumber: (state) => {
            state.medicineCart = 0
        },
        resetLabNumber: (state) => {
            state.labCart = 0
        },
    }
})

export const {increaseProductNumber, increaseMedicineNumber, increaseLabNumber, resetProductNumber, resetMedicineNumber, resetLabNumber} = cartNumber.actions
export default cartNumber.reducer