import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";

function AdminProducts() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/get/all`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(delProductData());
  }, []);

  function updateData(data) {
    dispatch(setProductData({ productData: data?._id }));
    return navigate("/admin/admin-edit-product");
  }

  async function delProduct(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/product/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Products</Heading2>
        <div className="w-full flex justify-end">
          <Button variant="outline" asChild>
            <Link to={"/admin/product-form"}>Add New Product</Link>
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Title</TableHead>
              <TableHead className="text-center">Image</TableHead>
              <TableHead className="text-center">Brand</TableHead>
              <TableHead className="text-center">Type</TableHead>
              <TableHead className="text-center">Total Variants</TableHead>
              <TableHead className="text-center">View Variants</TableHead>
              <TableHead className="text-center">Edit</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.title}</TableCell>
                  <TableCell>
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.pictures[0]}`}
                      alt=""
                      className="w-[3rem]"
                    />
                  </TableCell>
                  <TableCell>{e?.brand}</TableCell>
                  <TableCell>{e?.type}</TableCell>
                  <TableCell>{e?.groups?.length}</TableCell>
                  <TableCell>
                    <DialogBox title={"View"} contentTitle={"Groups"}>
                      {e?.groups?.map((j, i) => (
                        <div className="border px-3 py-3 rounded-md" key={i}>
                          <p>Title : {j?.title}</p>
                          <p>Available : {j?.isAvailable ? "Yes" : "No"}</p>
                          <p>Manufacture Date : {j?.manufactureDate}</p>
                          <p>Expire Date : {j?.expiryDate}</p>
                          <p>MRP : {j?.mrp}</p>
                          <p>Price : {j?.price}</p>
                          <p>Description : {j?.desc}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => updateData(e)}>Edit</Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delProduct(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminProducts;
