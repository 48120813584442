import React, { useState } from "react";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";

function AddPatient({ setReload }) {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate()
  const [dialogOpen, setDialogOpen] = useState(false);

  async function updatePatient(data) {
    data = { patient: { ...data } }
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/add-patient`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      if (setReload != undefined) {
        setReload(prev => !prev)
      }
      setOpen(true);
      setSuccess(true);
      return navigate("/my-profile")
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  }

  return (
    <DialogBox title={"Add Patient"} contentTitle={"Add Details of Pateint"} dialogOpen={dialogOpen} onOpenChange={setDialogOpen}>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Successfully updated"}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Update failed"}
        />
      )}
      <form className="space-y-3" onSubmit={handleSubmit(updatePatient)}>
        <InputField label="First Name" {...register("firstName")} />
        <InputField label="Last Name" {...register("lastName")} />
        <InputField label="Date Of Birth" type="date" {...register("dob")} />
        <InputField label="Gender" type="text" {...register("gender")} />
        <InputField label="Problems" type="text" {...register("problem")} />
        <Button>Save</Button>
      </form>
    </DialogBox>
  );
}

export default AddPatient;
