import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function ScheduledTest() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [testStatus, setTestStatus] = useState("");
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/tests/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueTests = allData.filter((e) => e?.testStatus !== "completed" && e?.testStatus !== "canceled");
      setProductsData(dueTests);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateStatus(paymentId) {
    console.log(paymentId);
    console.log(testStatus);
    if (testStatus?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select a status");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/test/update-status/${paymentId}/${testStatus}`,
        method: "put",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Status updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Status update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  console.log(productsData);

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Scheduled Lab Tests</Heading2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Booked Tests</TableHead>
              <TableHead className="text-center">Patient Name</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Price</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Test Status</TableHead>
              <TableHead className="text-center">Place</TableHead>
              <TableHead className="text-center">Address</TableHead>
              <TableHead className="text-center">Pincode</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <DialogBox
                      title={`${e?.tests?.length} Tests`}
                      contentTitle={"Included Tests"}
                    >
                      {e?.tests?.map((j, i) => (
                        <div
                          className="border px-3 py-3 rounded-md flex justify-between items-center"
                          key={i}
                        >
                          <p>{j?.labtest?.title}</p>
                          <DialogBox
                            title={j?.labtest?.includeTests?.length + " tests"}
                          >
                            {j?.labtest?.includeTests?.map((k, i) => (
                              <p key={i}>{k}</p>
                            ))}
                          </DialogBox>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{`${e?.patient?.firstName} ${e?.patient?.lastName}`}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.totalPrice}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>
                    <DialogBox
                      title={`${e?.testStatus === "initiated" ? "Booked" : e?.testStatus}`}
                      className={"w-[350px]"}
                    >
                      <div className="flex flex-col gap-2">
                        <Label>Select Status</Label>
                        <select
                          className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                          onChange={(e) => setTestStatus(e.target.value)}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Sample Collected">Sample Collected</option>
                          <option value="completed">Completed</option>
                          <option value="canceled">Canceled</option>
                        </select>
                        <DialogClose asChild>
                          <Button onClick={() => updateStatus(e?._id)}>
                            Save
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.place}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.address?.pincode}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default ScheduledTest;
