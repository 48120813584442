import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";

function AdminDoctors() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [catagory, setCatagory] = useState("")
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/doctor`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    dispatch(delProductData())
  },[])

  function updateData(data) {
    dispatch(setProductData({productData: data?._id}))
    return navigate("/admin/admin-edit-doctor")
  }

  async function delDoctor(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addCatagory() {
    //console.log(catagory);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/catagory/add`,
        method: "post",
        data: {
          name: catagory
        },
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Doctors</Heading2>
        <div className="w-full flex gap-2 justify-end">
          <DialogBox title={"Add Catagory"}>
            <InputField
              label="Catagory"
              onChange={(e) => setCatagory(e?.target?.value)}
            />
            <Button onClick={addCatagory}>Save</Button>
          </DialogBox>
          <Button variant="outline" asChild>
            <Link to={"/admin/doctors-form"}>Add New Doctor</Link>
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Image</TableHead>
              <TableHead className="text-center">Doctor</TableHead>
              <TableHead className="text-center">Designation</TableHead>
              <TableHead className="text-center">Place</TableHead>
              <TableHead className="text-center">Consult Mode</TableHead>
              <TableHead className="text-center">Edit</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className="w-[5rem] h-[5rem]">
                      <img
                        src={`${SERVER_URL}/api/v1/fetch/image/${e?.picture}`}
                        className="w-full h-full rounded-full object-cover "
                        alt=""
                      />
                    </div>
                  </TableCell>
                  <TableCell>Dr. {e?.name}</TableCell>
                  <TableCell>{e?.designation}</TableCell>
                  <TableCell>{e?.place}</TableCell>
                  <TableCell>
                    <DialogBox title={"View"} contentTitle={"Consult Mode"}>
                      {e?.consultType?.map((j, i) => (
                        <div className="border px-3 py-3 rounded-md" key={i}>
                          <p>Place : {j?.place}</p>
                          <p>Mode : {j?.consultMode}</p>
                          <p>Charge : {j?.charge}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => updateData(e)}>Edit</Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delDoctor(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminDoctors;
