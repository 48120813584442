import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function ScheduledAppointments() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [testStatus, setTestStatus] = useState("");
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/appointments/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueTests = allData.filter(
        (e) => e?.appointmentStatus === "scheduled"
      );
      setProductsData(dueTests);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateStatus(paymentId) {
    console.log(paymentId);
    console.log(testStatus);
    if (testStatus?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select a status");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/appointment/update-status/${paymentId}/${testStatus}`,
        method: "put",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Status updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Status update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  console.log(productsData);

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Scheduled Appointments</Heading2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Doctor Name</TableHead>
              <TableHead className="text-center">Designation</TableHead>
              <TableHead className="text-center">Patient Name</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Fees</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Appointment Status</TableHead>
              <TableHead className="text-center">Place</TableHead>
              <TableHead className="text-center">Appointment Date</TableHead>
              <TableHead className="text-center">Appointment Time</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell className="text-left">
                    Dr. {e?.doctor?.name}
                  </TableCell>
                  <TableCell>{e?.doctor?.designation}</TableCell>
                  <TableCell>{`${e?.patient?.firstName} ${e?.patient?.lastName}`}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.fees}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>
                    <DialogBox
                      title={`${e?.appointmentStatus}`}
                      contentTitle={"Update Status"}
                    >
                      <div className="flex flex-col gap-2">
                        <Label>Select Status</Label>
                        <select
                          className="w-full h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                          onChange={(e) => setTestStatus(e.target.value)}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="completed">Completed</option>
                          <option value="canceled">Canceled</option>
                        </select>
                        <DialogClose>
                          <Button onClick={() => updateStatus(e?._id)}>
                            Save
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.appointmentPlace}</TableCell>
                  <TableCell>{e?.appointmentDate?.date}</TableCell>
                  <TableCell>{e?.appointmentDate?.time}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default ScheduledAppointments;
