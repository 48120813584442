import CarouselCompo from "@/components/CarouselCompo";
import Heading2 from "@/components/Heading2";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GroupCarousel from "@/components/GroupCarousel";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import axios from "axios";
import { SERVER_URL } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { FaExclamationTriangle } from "react-icons/fa";
import UploadPrescription from "@/components/UploadPrescription";
import { FaTruck } from "react-icons/fa6";

function Landing() {
  const [loading, setLoading] = useState(false);
  const [carouselImgsArray, setCarouselImgsArray] = useState([]);
  const [trendingToday, setTrendingToday] = useState([]);
  const [limitedDeals, setLimitedDeals] = useState([]);
  const [bestsellers, setBestsellers] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [brands, setBrands] = useState([]);

  async function fetchBanners() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/banner/all`);
      //console.log(res.data.data);
      setCarouselImgsArray(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTrendingToday() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/trending_today`
      );
      //console.log(res.data.data);
      setTrendingToday(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchLimitedDeals() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/limited_deal`
      );
      //console.log(res.data.data);
      setLimitedDeals(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchBestseller() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/bestseller`
      );
      // console.log(res.data.data);
      setBestsellers(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagories() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/products/catagories`);
      //console.log(res.data.data);
      setCatagories(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchBrands() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/products/brands`);
      //console.log(res.data.data);
      setBrands(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBanners();
    fetchTrendingToday();
    fetchLimitedDeals();
    fetchBestseller();
    fetchCatagories();
    fetchBrands();
  }, []);

  const pincodeAvailable = useSelector((state) => state.availablePincode);
  console.log(pincodeAvailable);

  if (loading) {
    return <Loading color={"#37B7C3"} />;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-[2rem] lg:gap-[0.5rem] text-slate-700 m">
      <div
        className={`w-full ${
          pincodeAvailable?.isAvailable === null
            ? "bg-[#FF5722]"
            : `${
                pincodeAvailable?.isAvailable === false
                  ? "bg-red-500"
                  : "bg-green-500"
              }`
        } flex justify-center items-center mt-[-1rem] py-2`}
      >
        {pincodeAvailable?.isAvailable === null ? (
          <p className="text-white text-[13px] sm:text-[16px] font-semibold flex items-center gap-2">
            <FaExclamationTriangle />
            Please check your pincode
          </p>
        ) : pincodeAvailable?.isAvailable === false ? (
          <p className="text-white text-[13px] sm:text-[16px] font-semibold flex items-center gap-2">
            <FaExclamationTriangle />
            Service is not available at{" "}
            <span>
              <FaTruck size={"20px"} />
            </span>{" "}
            {pincodeAvailable?.pincode}
          </p>
        ) : (
          <p className="text-white text-[13px] sm:text-[16px] font-semibold flex items-center gap-2">
            <FaCircleCheck />
            Service is available at{" "}
            <span>
              <FaTruck size={"20px"} />
            </span>{" "}
            {pincodeAvailable?.pincode}
          </p>
        )}
      </div>
      <UploadPrescription />
      <div className="w-[90vw] h-[46%]">
        <CarouselCompo imgArray={carouselImgsArray} />
      </div>
      <div className="w-[90vw] text-center text-slate-800 flex flex-col justify-center py-5 items-center">
        <div className="w-full flex justify-between items-center">
          <Heading2>Trending Today</Heading2>
          <Link
            className="font-semibold text-[#37B7C3] text-[17px] hidden sm:flex"
            to={"/products"}
          >
            See All
          </Link>
        </div>
        <div className="w-full flex justify-between">
          <GroupCarousel dataArray={trendingToday} />
        </div>
        <Link
          className="font-semibold mt-5 text-[#37B7C3] text-[17px] flex sm:hidden"
          to={"/products"}
        >
          See All
        </Link>
      </div>
      <div className="w-[95vw] lg:w-[90vw] flex flex-col justify-center items-center text-center">
        <div className="w-full text-left mb-2">
          <Heading2>Shop By Catagory</Heading2>
        </div>
        <div className="w-full flex flex-wrap gap-2 justify-center items-center">
          {catagories &&
            catagories.map((e, i) => (
              <Link key={i} to={`/products/catagory/${e.name}`}>
                <section className="flex flex-col w-[30vw] lg:w-[17vw] md:w-[13rem] items-center gap-1 cursor-pointer text-center">
                  <div className="w-[70%] h-[70%]">
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e.image}`}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </div>
                  <p className="font-medium text-[12px] lg:text-[14px] mt-2">
                    {e.name}
                  </p>
                </section>
              </Link>
            ))}
        </div>
      </div>
      <div className="w-full bg-[#37B7C3] text-center text-white">
        <div className="flex flex-col justify-center py-5 items-center layer">
          <div className="w-[90vw] text-left">
            <p className="font-medium">Exclusive Offers On</p>
            <Heading2>Limited Time Deals</Heading2>
            <p className="mt-[-8px] text-[#fbff0f] font-semibold">
              Grab before the deal expires
            </p>
          </div>
          <div className="w-[90vw] mt-5 mb-5">
            <GroupCarousel dataArray={limitedDeals} />
          </div>
        </div>
      </div>
      <div className="w-[90vw] text-slate-800 flex flex-col justify-center py-5 items-center">
        <div className="w-full text-left">
          <Heading2>Bestsellers</Heading2>
        </div>
        <div className="w-[85vw] mt-5 mb-5 flex">
          <GroupCarousel dataArray={bestsellers} />
        </div>
      </div>
      <div className="w-[90vw] flex flex-wrap">
        <Heading2>Shop By Brand</Heading2>
        <div className="w-full flex flex-wrap justify-center sm:justify-start items-center mt-10 gap-10">
          {brands &&
            brands.map((e, i) => (
              <Link key={i} to={`/products/brands/${e.name}`}>
                <img
                  src={`${SERVER_URL}/api/v1/fetch/image/${e.image}`}
                  className="w-[4rem] sm:w-[6rem]"
                  alt=""
                />
              </Link>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
