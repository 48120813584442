import React, { useState } from "react";
import signupImg from "../assets/signup.jpg";
import CardComponent from "@/components/CardComponent";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import Loading from "@/components/Loading";
import { SERVER_URL } from "@/constant";
import { useDispatch } from "react-redux";
import { setNumber } from "@/store/phoneNumber.slice";

function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate()
  const dispatch = useDispatch()

  async function signup(data) {
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/auth/register`,
        method: "post",
        data: data,
      });
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        dispatch(setNumber({phoneNumber: data?.mobileNumber}))
        navigate("/signup/verify-otp")
        //console.log(data.mobileNumber);
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.message);
      //console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex h-[100vh]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Successfull"}
          desc={"Please log in to your account."}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <div className="w-full lg:w-1/2 h-[100vh] flex items-center justify-center flex-col gap-10">
        <img src={logo} className="w-[10rem]" alt="" />
        <CardComponent
          title={"Create An Account"}
          className={"w-[25rem]"}
          desc="Please register yourself"
        >
          <form className="space-y-2 mt-[1rem]" onSubmit={handleSubmit(signup)}>
          <InputField
              label={"First Name"}
              placeholder={"E.g, abcd@example.com"}
              {...register("firstName", {
                required: true,
              })}
            />
            {errors?.firstName && (
              <p className="text-red-500 text-[14px]">*Firstname is required</p>
            )}
            <InputField
              label={"Last Name"}
              placeholder={"E.g, abcd@example.com"}
              {...register("lastName", {
                required: true,
              })}
            />
            {errors?.lastName && (
              <p className="text-red-500 text-[14px]">*Lastname is required</p>
            )}
            <InputField
              label={"Email"}
              placeholder={"E.g, abcd@example.com"}
              type={"email"}
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-red-500 text-[14px]">*Email is required</p>
            )}
            <InputField
              label={"Mobile Number"}
              placeholder={"E.g, 9587456983"}
              type={"text"}
              {...register("mobileNumber", {
                pattern: {
                  value: /^-?\d+$/,
                  message: "Invalid number",
                },
                required: {
                  value: true,
                  message: "Mobile number is required"
                }
              })}
            />
            {errors?.mobileNumber && (
              <p className="text-red-500 text-[14px]">
                {errors?.mobileNumber?.message}
              </p>
            )}
            <InputField
              label={"Password"}
              placeholder={"E.g, Abcd@345"}
              type={"password"}
              {...register("password", {
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message:
                    "Minimum an uppercase letter, a lowercase letter, a number and a special character is required",
                },
                min: {
                  value: 8,
                  message: "Password should contain minimum 8 characters",
                },
                required: {
                  value: true,
                  message: "Password is required"
                }
              })}
            />
            {errors?.password && (
              <p className="text-red-500 text-[14px]">
                {errors?.password?.message}
              </p>
            )}
            <Button className="w-full text-[16px]">
              {loading === true ? <Loading color={"bg-white"} /> : "Sign Up"}
            </Button>
          </form>
          <p className="w-full text-center mt-3 text-[13px] text-slate-500">Already Have An Account? <Link to={"/signin"} className="underline">Login</Link></p>
        </CardComponent>
      </div>
      <div
        className={`w-1/2 h-[100vh] bg-center bg-cover hidden lg:flex`}
        style={{ backgroundImage: `url("${signupImg}")` }}
      >
      </div>
    </div>
  );
}

export default Signup;