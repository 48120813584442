import DialogBox from "@/components/DialogBox";
import Heading from "@/components/Heading";
import Heading2 from "@/components/Heading2";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { Link } from "react-router-dom";

function AddNewTest() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [productId, setProductId] = useState("");
  const [catagories, setCatagories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { register: infoRegister, handleSubmit: handleInfoSubmit } = useForm();
  const { register: includeTestRegister, handleSubmit: handleIncludeTestSubmit } = useForm();
  const [reload, setReload] = useState(false)
  const [productData, setProductData] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)

  async function updateInfo(data) {
    if(productId?.length > 0) {
      data = {...data, id: productId}
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/test/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setProductId(res.data?.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchTest() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/get/test/${productId}`,
        method: "get",
        withCredentials: true,
      });
      setProductData(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    fetchTest()
  },[reload])

  async function addIncludeTest(data) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
      return null;
    }
    data = { includeTests: data?.includedTests?.split(","), testId: productId };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/include-tests/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
      setDialogOpen(false)
    }
  }

  async function fetchCatagories() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delIncludeTest(consultData) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    const data = {
      testId: productId,
      includeTests: consultData,
    };
    console.log(consultData);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/include-tests/delete`,
        method: "delete",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    fetchCatagories();
  }, []);

  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full text-center">
        <Heading2>Add or Update Lab Tests</Heading2>
      </div>
      <div className="w-full">
        <form
          className="w-full flex flex-col gap-5 justify-center items-center mt-5"
          onSubmit={handleInfoSubmit(updateInfo)}
        >
          <div className="w-full flex flex-wrap gap-5 justify-center items-end mt-5">
            <InputField
              label="Title"
              {...infoRegister("title")}
              defaultValue={productData?.title}
            />
            <div className="flex flex-col gap-2">
              <Label>
                Select Catagory{" "}
                {productData?.catagory && (
                  <span className="text-slate-500">
                    (Prev: {productData?.catagory})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                {...infoRegister("catagory")}
              >
                {catagories?.map((e, i) => (
                  <option value={e?.name} key={i}>
                    {e?.name}
                  </option>
                ))}
              </select>
            </div>
            <InputField
              label="MRP"
              {...infoRegister("mrp")}
              defaultValue={productData?.mrp}
            />
            <InputField
              label="Price"
              {...infoRegister("price")}
              defaultValue={productData?.price}
            />
            <InputField
              label="Discount"
              {...infoRegister("discount")}
              defaultValue={productData?.discount}
            />
          </div>
          <Button>Save</Button>
        </form>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Included Tests
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            {productData?.includeTests?.map((j, i) => (
              <div className="border px-3 py-3 rounded-md" key={i}>
                <p>{j}</p>
                <Button
                  variant="destructive"
                  className="mt-5 w-full text-[13px] h-[2rem]"
                  onClick={() => delIncludeTest(j)}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <DialogBox title={"Add Test"} dialogOpen={dialogOpen} onOpenChange={setDialogOpen}>
            <form
              className="space-y-3"
              onSubmit={handleIncludeTestSubmit(addIncludeTest)}
            >
              <InputField label="Included Tests" {...includeTestRegister("includedTests")} />
              <Button>Save</Button>
            </form>
          </DialogBox>
        </div>
      </div>
    </div>
  );
}

export default AddNewTest;
