import React, { useState } from "react";
import DialogBox from "./DialogBox";
import { IoSearchOutline } from "react-icons/io5";
import InputField from "./InputField";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { DialogClose } from "./ui/dialog";
import Loading from "./Loading";

function SearchCompo() {
  const [open, setOpen] = useState(false);
  const [alerOpen, setAlertOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("Select Type");
  const [apiPath, setApiPath] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [docData, setDocData] = useState([]);

  function handleDropdownClick(title, path) {
    setSelectedTitle(title);
    setApiPath(path);
    setProductData([]);
    setLabData([]);
    setDocData([]);
    setOpen(false);
  }

  function searchChange(text) {
    const searchWord = text;
    if (searchWord?.length > 3) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setSearchKeyword(searchWord);
  }

  async function fetchData() {
    if (apiPath?.length === 0) {
      setAlertOpen(true);
      setSuccess(false);
      setMessage("Please select a type, you want to search.");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/search/${apiPath}/${searchKeyword}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      if (apiPath === "products") return setProductData(res.data.data);
      if (apiPath === "lab-tests") return setLabData(res.data.data);
      if (apiPath === "doctors") return setDocData(res.data.data);
    } catch (error) {
      setAlertOpen(true);
      setSuccess(false);
      setMessage("Orders fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DialogBox
        title={<IoSearchOutline size={"18px"} />}
        contentTitle={"Search"}
      >
        {success && (
          <AlertBox
            open={alerOpen}
            setOpen={setAlertOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={alerOpen}
            setOpen={setAlertOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-full flex gap-2 justify-start items-start flex-col flex-wrap">
          <div className="flex justify-start gap-3 mb-3 text-slate-700 w-full items-center">
            <label className="flex gap-2">
              <input
                type="radio"
                name="fruit"
                value="banana"
                onClick={() => handleDropdownClick("Product", "products")}
              />
              Product & Medicine
            </label>
            <label className="flex gap-2">
              <input
                type="radio"
                name="fruit"
                value="banana"
                onClick={() => handleDropdownClick("Lab Test", "lab-tests")}
              />
              Lab Tests
            </label>
            <label className="flex gap-2">
              <input
                type="radio"
                name="fruit"
                value="banana"
                onClick={() => handleDropdownClick("Doctor", "doctors")}
              />
              Doctor
            </label>
          </div>
          <div className="flex justify-between gap-3 flex-wrap items-center w-full">
            <InputField
              placeholder="Search"
              onChange={(e) => searchChange(e.target.value)}
            />
            {disableButton === true ? (
              <Button disabled>Search</Button>
            ) : (
              <Button onClick={fetchData}>Search</Button>
            )}
          </div>
        </div>
        {productData &&
          productData?.map((e, i) => (
            <div className="w-full">
              <DialogClose className="w-full" asChild>
                <Link to={`/product/${e?._id}`} target="_blank">
                  <section
                    key={i}
                    className="flex items-center gap-3 py-3 px-3 border-b-[1px] hover:bg-slate-50"
                  >
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.pictures[0]}`}
                      className="w-[40px]"
                      alt=""
                    />
                    <div className="space-y-1">
                      <p className="text-[16px]">{e?.title}</p>
                      <p className="flex items-center gap-2 text-[14px] text-slate-500">
                        See More <MdArrowForwardIos />
                      </p>
                    </div>
                  </section>
                </Link>
              </DialogClose>
            </div>
          ))}
        {labData &&
          labData?.map((e, i) => (
            <div className="w-full">
              <DialogClose className="w-full" asChild>
                <Link to={`/lab?id=${e?._id}`} target="_blank">
                  <section
                    key={i}
                    className="flex items-center gap-3 py-3 px-3 border-b-[1px] hover:bg-slate-50"
                  >
                    <div className="space-y-1">
                      <p className="text-[16px]">{e?.title}</p>
                      <p className="flex items-center gap-2 text-[14px] text-slate-500">
                        See More <MdArrowForwardIos />
                      </p>
                    </div>
                  </section>
                </Link>
              </DialogClose>
            </div>
          ))}
        {docData?.length > 0
          ? docData?.map((e, i) => (
              <div className="w-full">
                <DialogClose className="w-full" asChild>
                  <Link to={`/doctors?id=${e?._id}`} target="_blank">
                    <section
                      key={i}
                      className="flex items-center gap-3 py-3 px-3 border-b-[1px] hover:bg-slate-50"
                    >
                      <div className="w-[50px] h-[50px]">
                        <img
                          src={`${SERVER_URL}/api/v1/fetch/image/${e?.picture}`}
                          className="w-full h-full object-cover rounded-full"
                          alt=""
                        />
                      </div>
                      <div className="space-y-1">
                        <p className="text-[16px]">{e?.name}</p>
                        <p className="flex items-center gap-2 text-[14px] text-slate-500">
                          See More <MdArrowForwardIos />
                        </p>
                      </div>
                    </section>
                  </Link>
                </DialogClose>
              </div>
            ))
          : null}
      </DialogBox>
    </>
  );
}

export default SearchCompo;
