import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import AlertBox from "@/components/AlertBox";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { TfiReload } from "react-icons/tfi";
import { IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";

function AllOrderedTests() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/tests/all?search=${search}&startDate=${startDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleFileChange(event) {
    const selectedPdf = event.target.files[0];
    setFile(selectedPdf);
  }

  async function uploadPdf(paymentId) {
    const formData = new FormData();
    console.log(paymentId);
    console.log(file);

    formData.append("testReport", file);
    formData.append("paymentId", paymentId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/test/upload-report`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/generate/labtests/report`,
        method: "post",
        data: {
          startDate,
          endDate,
          data: productsData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Report generate failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  console.log(productsData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Lab Test History</Heading2>
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end items-center w-full mt-5 gap-3">
          <NavLink
            to={"/admin/scheduled-tests"}
            className="font-semibold text-blue-500 hover:underline"
          >
            Scheduled Tests
          </NavLink>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Order Number</TableHead>
              <TableHead className="text-center">Booked Tests</TableHead>
              <TableHead className="text-center">Patient Name</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Price</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Test Status</TableHead>
              <TableHead className="text-center">Place</TableHead>
              <TableHead className="text-center">Address</TableHead>
              <TableHead className="text-center">Pincode</TableHead>
              <TableHead className="text-center">Report</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.invoiceId}</TableCell>
                  <TableCell>
                    <DialogBox
                      title={`${e?.tests?.length} Tests`}
                      contentTitle={"Included Tests"}
                    >
                      {e?.tests?.map((j, i) => (
                        <div
                          className="border px-3 py-3 rounded-md flex justify-between items-center"
                          key={i}
                        >
                          <p>{j?.labtest?.title}</p>
                          <DialogBox
                            title={j?.labtest?.includeTests?.length + " tests"}
                          >
                            {j?.labtest?.includeTests?.map((k, i) => (
                              <p key={i}>{k}</p>
                            ))}
                          </DialogBox>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{`${e?.patient?.firstName} ${e?.patient?.lastName}`}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.totalPrice}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>{e?.testStatus}</TableCell>
                  <TableCell>{e?.place}</TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.address?.pincode}</TableCell>
                  <TableCell>
                    <DialogBox title={"Report"}>
                      <div className="w-full space-y-3">
                        <div className="w-full rounded-lg">
                          <InputField
                            label="Upload Report"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <Button
                            className="w-full mt-3"
                            onClick={() => uploadPdf(e?._id)}
                          >
                            Upload
                          </Button>
                        </div>
                        <div className="mt-3 mb-3 h-[1px] bg-slate-300 w-full"></div>
                        {e?.report?.length > 0 && (
                          <Button asChild className="w-full">
                            <Link
                              to={`${SERVER_URL}/api/v1/fetch/image/${e?.report}`}
                              target="_blank"
                            >
                              View Report
                            </Link>
                          </Button>
                        )}
                      </div>
                    </DialogBox>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AllOrderedTests;
