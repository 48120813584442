import React from "react";
import { Button } from "./ui/button";
import test from "@/assets/facewash_icon.png";
import { BsCartPlus } from "react-icons/bs";
import DialogBox from "./DialogBox";
import AddToLabCart from "./AddToLabCart";

function LabProduct({ id, img, title, testArray, mrp, price, discount }) {
  return (
    <section className="w-[80vw] sm:max-w-full flex flex-col items-center justify-center gap-3 border px-3 py-4 rounded-lg">
      <div className="w-full flex gap-2 justify-center items-center">
        <div className="w-full flex gap-2 flex-col sm:flex-row">
          {img && <img src={img} className="w-[3rem] h-[3rem]" alt="" />}
          <div className="w-full">
            <p className="text-[15px] font-semibold truncate">{title}</p>
            <p className="text-[14px] text-slate-600 mt-1">
              {testArray?.length} tests included
            </p>
          </div>
        </div>
      </div>
      <section className="w-full">
        <p className="text-[16px] space-x-1">
          <span className="font-semibold">₹{price}</span>{" "}
          <span className="text-slate-500 text-[13px]">
            <strike>₹{mrp}</strike>
          </span>{" "}
          {discount && (
            <span className="text-green-600 font-semibold text-[14px]">
              {discount}% off
            </span>
          )}
        </p>
      </section>
      <div className="flex justify-center w-full items-center gap-2 flex-col">
        <DialogBox
          title={"View Details"}
          buttonClass={"w-full"}
          contentTitle={title}
        >
          <ol type="1">
            {testArray && testArray?.map((e, i) => <li key={i}>{e}</li>)}
          </ol>
        </DialogBox>
        <AddToLabCart productId={id} price={price} />
      </div>
    </section>
  );
}

export default LabProduct;
