import Heading2 from "@/components/Heading2";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function OrderedProduct() {
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/ordered/product/get/${productId}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setProductData(res.data.data[0]);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(productData);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[90vw] sm:w-[40rem]">
        <Heading2>
          Ordered{" "}
          {productData?.products?.length > 1
            ? `${productData?.products?.length} items`
            : `${productData?.products?.length} item`}
        </Heading2>
        <section>
          <p>Order date : {productData?.date?.split("T")[0]}</p>
          <p>
            Status :{" "}
            {productData?.delivaryStatus === "delivared" ? (         
              <span className="text-green-600 font-semibold">
                {productData?.delivaryStatus?.toUpperCase()}
              </span>
            ) : (
              <span className="text-red-600 font-semibold">
                {productData?.delivaryStatus === "initiated" ? "Confirmed" : productData?.delivaryStatus}
              </span>
            )}
          </p>
        </section>
        {productData?.delivaryStatus === "delivared" && (
          <Link
            to={`${SERVER_URL}/api/v1/fetch/image/${productData?.invoice}`}
            target="_blank"
          >
            <section className="flex items-center justify-between w-full border mt-5 px-5 py-3 rounded-lg">
              <p>Download Invoice</p>
              <IoIosArrowForward />
            </section>
          </Link>
        )}
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">Products</p>
          <div
            className="w-full flex flex-col gap-2 justify-start items-center mt-3 border p-5 rounded-lg"
            style={{ scrollbarWidth: "none" }}
          >
            {productData?.products?.map((e, i) => (
              <>
                <section className="flex justify-between items-start w-full flex-shrink-0">
                  <img
                    src={`${SERVER_URL}/api/v1/fetch/image/${e?.product?.pictures[0]}`}
                    className="w-[3rem]"
                    alt=""
                  />
                  <div className="text-right">
                    <p className="font-medium">{e?.product?.title}</p>
                    <p>QTY : {e?.quantity}</p>
                    <p>Rs. {e?.price}</p>
                  </div>
                </section>
              </>
            ))}
          </div>
        </div>
        <p className="mt-5 font-medium">
          Payment Method:{" "}
          <span className="text-slate-500 font-medium">
            {productData?.paymentMethod === "offline"
              ? "Cash On Delivary"
              : "Card/Netbanking/Wallet"}
          </span>
        </p>
        <p className="mt-1 font-medium">
          Payment Status:{" "}
          <span className="text-slate-500 font-medium">
            {productData?.paymentStatus === "due" ? "Payment Pending" : (productData?.paymentStatus === "success" ? "Payment Recived" : "Payment Canceled")}
          </span>
        </p>
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">
            Delivary Address
          </p>
          <div className="w-full mt-3 border p-3 rounded-lg">
            <div className="text-left">
              <p>
                {productData?.user?.firstName +
                  " " +
                  productData?.user?.lastName}
              </p>
              <p>{productData?.address?.address}</p>
              <p>Pincode : {productData?.address?.pincode}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-5 mb-10 items-center">
          <p className="text-[25px] font-semibold">Order Total</p>
          <p className="text-red-600 font-medium text-[18px]">Rs. {productData?.totalPrice}</p>
        </div>
      </div>
    </div>
  );
}

export default OrderedProduct;
