import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "@/components/Heading";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { MdCloudDownload } from "react-icons/md";

function DueOrders() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [testStatus, setTestStatus] = useState("");
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/products/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueTests = allData.filter((e) => e?.delivaryStatus !== "delivared" && e?.delivaryStatus !== "canceled");
      setProductsData(dueTests);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateStatus(paymentId) {
    console.log(paymentId);
    console.log(testStatus);
    if (testStatus?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select a status");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/order/update-status/${paymentId}/${testStatus}`,
        method: "put",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Status updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Status update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  console.log(productsData);

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Pending Orders</Heading2>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-center">Order Number</TableHead>
              <TableHead className="text-center">Products</TableHead>
              <TableHead className="text-center">Total Price</TableHead>
              <TableHead className="text-center">Coupon</TableHead>
              <TableHead className="text-center">Discount</TableHead>
              <TableHead className="text-center">User</TableHead>
              <TableHead className="text-center">User Mobile No</TableHead>
              <TableHead className="text-center">Payment Method</TableHead>
              <TableHead className="text-center">Payment Status</TableHead>
              <TableHead className="text-center">Delivary Status</TableHead>
              <TableHead className="text-center">Address</TableHead>
              <TableHead className="text-center">Pincode</TableHead>
              <TableHead className="text-center">Date of Order</TableHead>
              <TableHead className="text-center">Download Invoice</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>{e?.invoiceId}</TableCell>
                  <TableCell className="text-center">
                    <DialogBox
                      title={`${
                        e?.products?.length === 1
                          ? "1 Product"
                          : `${e?.products?.length} Products`
                      }`}
                      contentTitle={"Included Tests"}
                    >
                      {e?.products?.map((j, i) => (
                        <div
                          className="border flex justify-between px-3 py-3 rounded-md"
                          key={i}
                        >
                          <p>{j?.product?.title}</p>
                          <p>Rs. {j?.price}</p>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.totalPrice}</TableCell>
                  <TableCell>
                    {e?.coupon?.length > 0 ? `${e?.coupon}` : "None"}
                  </TableCell>
                  <TableCell>
                    {e?.discount > 0 ? `${e?.discount}` : "0"}
                  </TableCell>
                  <TableCell>{e?.user?.firstName + " " + e?.user?.lastName}</TableCell>
                  <TableCell>{e?.user?.mobileNumber}</TableCell>
                  <TableCell>{e?.paymentMethod}</TableCell>
                  <TableCell>{e?.paymentStatus}</TableCell>
                  <TableCell>
                    <DialogBox
                      title={`${e?.delivaryStatus === "initiated" ? "Order Confirmed" : e?.delivaryStatus}`}
                      contentTitle={"Update Status"}
                      className={"w-[350px]"}
                    >
                      <div className="flex flex-col gap-2">
                        <Label>Select Status</Label>
                        <select
                          className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                          onChange={(e) => setTestStatus(e.target.value)}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Order Prepared">Order Prepared</option>
                          <option value="Order Dispatched">Order Dispatched</option>
                          <option value="Out For Delivary">Out For Delivary</option>
                          <option value="delivared">Delivered</option>
                          <option value="canceled">Canceled</option>
                        </select>
                        <DialogClose className="w-full">
                          <Button onClick={() => updateStatus(e?._id)}>
                            Save
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.address?.address}</TableCell>
                  <TableCell>{e?.address?.pincode}</TableCell>
                  <TableCell>{e?.date?.toString()?.split("T")[0]}</TableCell>
                  <TableCell>
                    {e?.invoice?.length > 0 || e?.invoice != undefined ? (
                      <Button asChild className="w-auto" variant="ghost">
                        <Link
                          to={`${SERVER_URL}/api/v1/fetch/image/${e?.invoice}`}
                          target="_blank"
                        >
                          <MdCloudDownload size={"30px"} className="text-blue-500" />
                        </Link>
                      </Button>
                    ) : (
                      <Button className="w-auto" disabled variant="ghost">
                        <Link
                          to={`${SERVER_URL}/api/v1/fetch/image/${e?.invoice}`}
                          target="_blank"
                        >
                          <MdCloudDownload size={"30px"} className="text-blue-500" />
                        </Link>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default DueOrders;
