import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function AdminBanner() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [file, setFile] = useState("");
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/banner/all`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delDoctor(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/banner/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addCatagory() {
    if (!file) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a image");
      return null;
    }
    const formData = new FormData();
    formData.append("bannerImg", file);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/banner/add`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Banners</Heading2>
        <p>
          Best Size of a banner is{" "}
          <span className="font-bold">Width: 1600px</span> and{" "}
          <span className="font-bold">Height: 304px</span>
        </p>
        <div className="w-full flex gap-2 justify-end">
          <DialogBox title={"Add Banner"}>
            <InputField
              label="Banner"
              type="file"
              onChange={(e) => setFile(e?.target?.files[0])}
            />
            <DialogClose>
              <Button onClick={addCatagory}>Save</Button>
            </DialogClose>
          </DialogBox>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Banner</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`}
                      className="w-[50rem]"
                      alt=""
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="destructive"
                      onClick={() => delDoctor(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminBanner;
