import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { delProductData, setProductData } from "@/store/productDataSlice";
import Heading from "@/components/Heading";
import { useForm } from "react-hook-form";
import InputField from "@/components/InputField";
import { FiEdit } from "react-icons/fi";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function AdminProductCatagory() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [file, setFile] = useState(null);
  const [brandName, setBrandname] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/catagories`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delProduct(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/delete-catagory`,
        method: "delete",
        data: {
          id: deleteProductId,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addBrand() {
    if (!file || brandName?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a image and a brand name");
      return null;
    }
    const formData = new FormData();
    formData.append("catagoryImg", file);
    formData.append("name", brandName);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/add-catagory`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully Added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateImage(id) {
    if (!file) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a image");
      return null;
    }
    const formData = new FormData();
    formData.append("catagoryImg", file);
    formData.append("id", id);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/add-catagory`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Updated failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //console.log(productsData);

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Catagories</Heading2>
        <div className="w-full flex justify-end">
          <DialogBox title={"Add New Catagory"}>
            <div className="w-full space-y-3">
              <InputField
                label="Name"
                onChange={(e) => setBrandname(e.target.value)}
              />
              <InputField
                label="Logo"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
              <DialogClose>
                <Button onClick={addBrand}>Save</Button>
              </DialogClose>
            </div>
          </DialogBox>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Image</TableHead>
              <TableHead className="text-center">Name</TableHead>
              <TableHead className="text-center">Total Product</TableHead>
              <TableHead className="text-right">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell className="flex items-center gap-2">
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`}
                      alt=""
                      className="w-[3rem]"
                    />
                    <DialogBox
                      title={<FiEdit />}
                      buttonClass={"rounded-full p-3"}
                      contentTitle={"Update Image"}
                    >
                      <div className="w-full space-y-3">
                        <InputField
                          label="Image"
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                        <DialogClose>
                          <Button onClick={() => updateImage(e?._id)}>
                            Save
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogBox>
                  </TableCell>
                  <TableCell>{e?.name}</TableCell>
                  <TableCell>
                    <DialogBox title={`${e?.products} Products`}>
                      {e?.product?.map((j, i) => (
                        <div
                          className="border px-3 flex justify-between py-3 rounded-md"
                          key={i}
                        >
                          <p>{j?.title}</p>
                          <Link
                            to={`/product/${j?._id}`}
                            target="_blank"
                            className="text-blue-500"
                          >
                            View
                          </Link>
                        </div>
                      ))}
                    </DialogBox>
                  </TableCell>
                  <TableCell className="text-right">
                    <Button
                      variant="destructive"
                      onClick={() => delProduct(e?._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminProductCatagory;
