import Heading2 from "@/components/Heading2";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function OrderedAppointment() {
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/ordered/appointment/get/${productId}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(productData);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[90vw] sm:w-[40rem]">
        <Heading2>
          Booked Appointment with Dr. {productData?.doctor?.name}
        </Heading2>
        <span className="bg-slate-200 px-3 py-1 text-slate-700 rounded-lg">{productData?.doctor?.designation}</span>
        <section className="mt-3">
          <p>Order date : {productData?.date?.split("T")[0]}</p>
          <p>Appointment ID : {productData?.order_id}</p>
          <p>
            Status :{" "}
            {productData?.appointmentStatus === "completed" ? (
              <span className="text-green-600 font-semibold">
                {productData?.appointmentStatus?.toUpperCase()}
              </span>
            ) : (
              <span className="text-red-600 font-semibold">
                {productData?.appointmentStatus?.toUpperCase()}
              </span>
            )}
          </p>
        </section>
        {productData?.report?.length > 0 && (
          <Link
            to={`${SERVER_URL}/api/v1/fetch/image/${productData?.report}`}
            target="_blank"
          >
            <section className="flex items-center justify-between w-full border mt-5 px-5 py-3 rounded-lg">
              <p>Download Prescription</p>
              <IoIosArrowForward />
            </section>
          </Link>
        )}
        <p className="mt-5 font-medium">
          Payment Method:{" "}
          <span className="text-slate-500 font-medium">
            {productData?.paymentMethod === "offline"
              ? "Pay At Hospital"
              : "Card/Netbanking/Wallet"}
          </span>
        </p>
        <p className="mt-1 font-medium">
          Payment Status:{" "}
          <span className="text-slate-500 font-medium">
            {productData?.paymentStatus === "due" ? "Payment Pending" : (productData?.paymentStatus === "success" ? "Payment Recived" : "Payment Canceled")}
          </span>
        </p>
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">
            Appointment Details
          </p>
          <div className="w-full mt-3 border p-3 rounded-lg">
            <div className="text-left">
              <p>Date : {productData?.appointmentDate?.date}</p>
              <p>Time : {productData?.appointmentDate?.time}</p>
              <p>Place : {productData?.appointmentPlace}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">
            Patient Details
          </p>
          <div className="w-full mt-3 border p-3 rounded-lg">
            <div className="text-left">
              <p>
                {productData?.patient?.firstName +
                  " " +
                  productData?.patient?.lastName}
              </p>
              <p>Date of birth : {productData?.patient?.dob}</p>
              <p>Problems : {productData?.patient?.problem}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-5 mb-10 items-center">
          <p className="text-[25px] font-semibold">Fees</p>
          <p className="text-red-600 font-medium text-[18px]">
            Rs. {productData?.fees}
          </p>
        </div>
        <div className="w-full rounded-[20px]">
          <p className="font-medium text-[20px] bg-green-100 p-3">Note</p>
          <section className="space-y-3 p-3">
            <p className="text-slate-800">1. Please note in the case of Emergency the appointment date or time might be rescheduled or might be delayed. Please co-operate.</p>
            <p className="text-slate-800">2. One-time Hospital Registration Charges might be applicable for the new users.</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default OrderedAppointment;
