import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pincode: null,
    isAvailable: null
}

const availablePincode = createSlice({
    name: "availablePincode",
    initialState,
    reducers: {
        setPincode: (state, action) => {
            state.pincode = action.payload.pincode,
            state.isAvailable = action.payload.isAvailable
        },
        deletePincode: (state) => {
            state.pincode = null,
            state.isAvailable = null
        }
    }
})

export const {setPincode, deletePincode} = availablePincode.actions
export default availablePincode.reducer