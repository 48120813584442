import React from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "@/constant";

function Sidebar({catagories}) {

  return (
    <div
      className="w-[4rem] fixed bottom-0 left-0 h-[calc(100vh-12rem)] border-r-[1px] px-[5px] py-3 flex flex-col overflow-auto justify-start items-center gap-5"
      style={{ scrollbarWidth: "none" }}
    >
      {catagories &&
        catagories.map((e, i) => (
          <Link key={i} to={`/products/subcatagory/${e.name}`}>
            <section
              className="w-[3rem] space-y-1 text-center flex flex-col justify-center items-center"
              key={i}
            >
              <img src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`} className="w-[2.5rem]" alt="" />
              <p className="text-[12px] text-slate-600 font-semibold">
                {e?.name}
              </p>
            </section>
          </Link>
        ))}
    </div>
  );
}

export default Sidebar;
