import { configureStore } from "@reduxjs/toolkit";
import phoneNumberSlice from "./phoneNumber.slice";
import authSlice from "./auth.slice";
import productDataSlice from "./productDataSlice";
import availablePincode from "./pincode.slice"
import cartNumber from "./cartNumber.slice";

export const store = configureStore({
    reducer: {
        phoneNumberSlice: phoneNumberSlice,
        auth: authSlice,
        productData: productDataSlice,
        availablePincode: availablePincode,
        cartNumber: cartNumber
    }
})