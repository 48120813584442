import React, { useEffect, useState } from "react";
import logo from "@/assets/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { RxHamburgerMenu } from "react-icons/rx";
import DropDownBox from "./DropDownBox";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import {
  IoCarSharp,
  IoCartOutline,
  IoCartSharp,
  IoSearchOutline,
} from "react-icons/io5";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import Heading from "./Heading";
import { CiLogin } from "react-icons/ci";
import { BsCartPlus } from "react-icons/bs";
import { GoPersonAdd } from "react-icons/go";
import { BsBox } from "react-icons/bs";
import { PiStethoscopeLight } from "react-icons/pi";
import { SlLogout } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "./AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { logout } from "@/store/auth.slice";
import { Separator } from "./ui/separator";
import { BsCart } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import PincodeCompo from "./PincodeCompo";
import { Label } from "./ui/label";
import SearchCompo from "./SearchCompo";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [handleBorder, setHandleBorder] = useState(false);
  const [navigationArray, setNavigationArray] = useState([]);
  const [dropdwonArray, setDropdownArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [logoObj, setLogoObj] = useState({});
  const [navigationOptions, setNavigationOptions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/client/logonav`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      const allData = res.data.data;
      let logoData = {};
      let navData = [];
      allData?.map((e, i) => {
        if (e?.type === "logo") {
          logoData.link = e?.link;
          logoData.title = e?.title;
          logoData.image = e?.image;
        } else {
          let nav = {
            tag: e?.title,
            link: e?.link,
            image: e?.image,
          };
          navData.push(nav);
        }
      });
      setLogoObj(logoData);
      setNavigationOptions(navData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleScroll() {
    if (window.scrollY > 0) {
      setHandleBorder(true);
    } else {
      setHandleBorder(false);
    }
  }

  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function logoutUser() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/auth/logout`,
        method: "get",
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const accountsDropdown = [
    <div className="w-full">
      {!isAuthenticated && (
        <div className="py-2 px-2 hover:bg-slate-100 w-full text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center gap-2 w-full justify-between"
            to={"/signup"}
          >
            Sign Up
            <GoPersonAdd size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {!isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/signin"}
          >
            Sign In
            <CiLogin size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-profile"}
          >
            My Profile
            <GoPerson size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-order"}
          >
            My Product Orders
            <BsBox size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-lab-order"}
          >
            My Lab Orders
            <BsBox size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-appointments"}
          >
            My Appointments
            <PiStethoscopeLight size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between text-red-500"
            onClick={logoutUser}
          >
            Logout
            <SlLogout size={"18px"} />
          </Link>
        </div>
      )}
    </div>,
  ];

  const productNumber = useSelector((state) => state.cartNumber?.productCart);
  const medicineNumber = useSelector((state) => state.cartNumber?.medicineCart);
  const labNumber = useSelector((state) => state.cartNumber?.labCart);
  const cartNumber = productNumber + medicineNumber + labNumber;

  const cartDropdown = [
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-lab-cart"}
          >
            My Lab Cart
            <p>{labNumber}</p>
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-product-cart"}
          >
            My Product Cart
            <p>{productNumber}</p>
          </Link>
        </div>
      )}
    </div>,
    <div className="w-full">
      {isAuthenticated && (
        <div className="py-2 px-2 w-full hover:bg-slate-100 text-[14px] rounded-md">
          <Link
            className="font-medium flex items-center w-full gap-2 justify-between"
            to={"/my-medicine-cart"}
          >
            My Medicine Cart
            <p>{medicineNumber}</p>
          </Link>
        </div>
      )}
    </div>,
  ];

  const isAvailablePincode = useSelector(
    (state) => state.availablePincode?.isAvailable
  );
  //console.log(isAvailablePincode);

  useEffect(() => {
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 1388 && viewportWidth > 1320) {
      let newNavigationArray = [...navigationOptions];
      let navLen = navigationOptions.length;

      setDropdownArray((prev) => [
        ...prev,
        { ...navigationOptions[navLen - 1] },
      ]);
      newNavigationArray.splice(navLen - 1, 1);

      setNavigationArray(newNavigationArray);
    } else if (viewportWidth < 1320 && viewportWidth > 1300) {
      let newNavigationArray = [...navigationOptions];
      let navLen = navigationOptions.length;

      setDropdownArray((prev) => [
        ...prev,
        { ...navigationOptions[navLen - 1] },
        { ...navigationOptions[navLen - 2] },
      ]);
      newNavigationArray.splice(navLen - 2, 2);

      setNavigationArray(newNavigationArray);
    } else if (viewportWidth < 1300 && viewportWidth > 1024) {
      let newNavigationArray = [...navigationOptions];
      let navLen = navigationOptions.length;

      setDropdownArray((prev) => [
        ...prev,
        { ...navigationOptions[navLen - 1] },
        { ...navigationOptions[navLen - 2] },
        { ...navigationOptions[navLen - 3] },
        { ...navigationOptions[navLen - 4] },
        { ...navigationOptions[navLen - 5] },
      ]);
      newNavigationArray.splice(navLen - 5, 5);

      setNavigationArray(newNavigationArray);
    } else {
      setNavigationArray(navigationOptions);
    }
    //console.log(`Viewport Width: ${viewportWidth}px`);
  }, []);

  return (
    <header
      className={`w-full flex h-[6rem] justify-between items-center z-[1] fixed bg-white top-0 ${
        handleBorder === true ? "border-b-[1px]" : ""
      }`}
    >
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Logout Failed"}
        />
      )}
      <div className="w-full flex justify-between items-center px-10 py-3">
        <section>
          <Link to={"/"}>
            <img
              src={`${SERVER_URL}/api/v1/fetch/image/${logoObj?.image}`}
              className="w-[11rem] sm:w-[8rem]"
              alt=""
            />
          </Link>
        </section>
        <div
          className="flex w-full lg:hidden justify-end items-end cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <RxHamburgerMenu size={"30px"} />
        </div>
        <section className="hidden lg:flex gap-5 text-slate-600">
          <div className="flex justify-center items-end flex-wrap gap-2 ">
            {navigationOptions.map((e, i) => (
              <Link key={i} to={e.link}>
                <section className="flex flex-col justify-center items-center gap-3 px-3 cursor-pointer text-center">
                  {e?.image?.length > 0 ? (
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`}
                      className="w-[2rem]"
                      alt=""
                    />
                  ) : null}
                  <p className="font-medium text-[12px]">{e.tag}</p>
                </section>
              </Link>
            ))}
            {dropdwonArray?.length > 0
              ? dropdwonArray[0]?.length > 0 && (
                  <section className="flex flex-col justify-center items-center gap-1">
                    <DropDownBox
                      title={<BsThreeDotsVertical size={"17px"} />}
                      menuArray={dropdwonArray}
                    />
                    <p className="text-[12px] font-medium">More</p>
                  </section>
                )
              : null}
          </div>
        </section>
        <section className="hidden lg:flex flex-col justify-center items-center gap-1">
          <div className="flex justify-center items-center gap-3">
            {isAuthenticated && (
              <DropDownBox
                title={
                  <p className="flex items-center justify-center gap-2">
                    <IoCartOutline size={"20px"} /> <span>{cartNumber}</span>
                  </p>
                }
                buttonArray={cartDropdown}
              />
            )}
            <DialogBox
              dialogOpen={dialogOpen}
              onOpenChange={setDialogOpen}
              title={
                <section className="flex items-center gap-3">
                  <FaLocationDot
                    size={"18px"}
                    className={`${
                      isAvailablePincode === true
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  />
                </section>
              }
              buttonClass={`${
                isAvailablePincode === true
                  ? "bg-green-50 border-green-600"
                  : "bg-red-50 border-red-600"
              }`}
              contentTitle={
                <Label className="text-slate-500">
                  Please Enter Your Pincode
                </Label>
              }
            >
              <div className="w-full flex gap-2 justify-center items-center">
                <PincodeCompo setDialogOpen={setDialogOpen} />
              </div>
            </DialogBox>
            <SearchCompo />
            <DropDownBox
              title={
                <p className="flex items-center justify-center gap-2">
                  <GoPerson size={"18px"} />
                </p>
              }
              buttonArray={accountsDropdown}
            />
          </div>
          {/* <p className="text-[13px] text-left w-full text-green-600">Service is available</p> */}
        </section>
      </div>
      <div
        className={`w-full h-[100vh] bg-[#63636348] fixed top-0 left-0 transition-all duration-300 ease-in-out ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={(e) => {
          setIsOpen(false);
        }}
      >
        <div
          className={`w-[15rem] bg-white h-full fixed left-0 top-0 py-5 flex flex-col items-center 
    transition-transform duration-300 ease-in-out transform ${
      isOpen ? "translate-x-0" : "-translate-x-full"
    }`}
        >
          <section>
            <img src={logo} className="w-[10rem]" alt="" />
          </section>
          <div
            className="w-full flex flex-col mt-10 gap-0 justify-start items-center overflow-scroll"
            style={{ scrollbarWidth: "none" }}
          >
            <section className="flex justify-center items-center gap-3">
              {isAuthenticated && (
                <DropDownBox
                  title={
                    <p className="flex items-center justify-center gap-2">
                      <IoCartOutline size={"20px"} /> <span>{cartNumber}</span>
                    </p>
                  }
                  buttonArray={cartDropdown}
                />
              )}
              <DialogBox
                title={
                  <section className="flex items-center gap-3">
                    <FaLocationDot
                      size={"18px"}
                      className={`${
                        isAvailablePincode === true
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    />
                  </section>
                }
                buttonClass={`${
                  isAvailablePincode === true
                    ? "bg-green-50 border-green-600"
                    : "bg-red-50 border-red-600"
                }`}
                contentTitle={
                  <Label className="text-slate-500">
                    Please Enter Your Pincode
                  </Label>
                }
              >
                <div className="w-full flex gap-2 justify-center items-center">
                  <PincodeCompo />
                </div>
              </DialogBox>
              <SearchCompo />
            </section>
            <div className="w-full flex flex-col justify-center gap-2 px-2 mb-3">
              {navigationOptions.map((e, i) => (
                <Link to={`${e.link}`}>
                  <section className="w-full flex justify-between items-center px-4 gap-2 hover:bg-slate-100 rounded-2xl py-2">
                    <p className="text-left text-[14px] font-medium">{e.tag}</p>
                    {e?.image?.length > 0 ? (
                      <img
                        src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`}
                        className="w-[2.5rem]"
                        alt=""
                      />
                    ) : null}
                  </section>
                </Link>
              ))}
            </div>
            <Separator />
            <div className="flex flex-col justify-center gap-2 px-4 w-full mt-2">
              {accountsDropdown.map((e, i) => e)}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
