import React, { useState } from "react";
import { Calendar } from "./ui/calendar";
import DialogBox from "./DialogBox";
import { Button } from "./ui/button";
import { DialogClose } from "./ui/dialog";
import AlertBox from "./AlertBox";

function SelectCalander({ date, setDate, setSelectPlace }) {
  console.log(date);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  function handleDate(d) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(d);
    const isPast = selectedDate < today;

    if (isPast === true) {
      setOpen(true);
      setSuccess(false);
      setMessage("You have chosen a past date. Please choose a future date.");
      return null;
    }
    setDate(
      new Date(selectedDate)
        .toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
        .replace(/,/g, "")
    );
    setOpen(true);
    setSuccess(true);
    setMessage("Date has been selected. Now select the place.");
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <DialogBox
        title={"Choose A Date"}
        className={"w-auto"}
        desc={"Select a date for your test"}
      >
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDate}
          className="w-full rounded-md border"
        />
        <div className="w-full space-y-2">
          <p className="font-semibold">Test at</p>
          <div className="w-full flex justify-center gap-2">
            <DialogClose asChild>
              <Button className="w-full" onClick={() => setSelectPlace("home")}>
                Home
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button className="w-full" onClick={() => setSelectPlace("lab")}>
                Lab
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogBox>
    </>
  );
}

export default SelectCalander;
