import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./page/Layout";
import Landing from "./page/Landing";
import Product from "./page/Product";
import Medicines from "./page/Medicines";
import Signin from "./page/Signin";
import Signup from "./page/Signup";
import Doctors from "./page/Doctors";
import LabTest from "./page/LabTest";
import Cart from "./page/Cart";
import MyProfile from "./page/MyProfile";
import MyOrder from "./page/MyOrder";
import MyAppointments from "./page/MyAppointments";
import VerifyOtp from "./page/VerifyOtp";
import { SERVER_URL } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { login, logout } from "./store/auth.slice";
import AdminLanding from "./page/admin/AdminLanding";
import AdminLayout from "./page/admin/AdminLayout";
import AdminProducts from "./page/admin/AdminProducts";
import ProductForm from "./page/admin/ProductForm";
import AdminDoctors from "./page/admin/AdminDoctors";
import AddNewDoc from "./page/admin/AddNewDoc";
import AdminLabTests from "./page/admin/AdminLabTests";
import AddNewTest from "./page/admin/AddNewTest";
import ScheduledTest from "./page/admin/ScheduledTest";
import AllOrderedTests from "./page/admin/AllOrderedTests";
import ScheduledAppointments from "./page/admin/SheduledAppointments";
import AllAppointments from "./page/admin/AllAppointments";
import DueOrders from "./page/admin/DueOrders";
import AllOrders from "./page/admin/AllOrders";
import AdminBrands from "./page/admin/AdminBrands";
import AdminProductCatagory from "./page/admin/AdminProductCatagory";
import AdminPrescriptions from "./page/admin/AdminPrescriptions";
import AdminPincode from "./page/admin/AdminPincode";
import AdminBanner from "./page/admin/AdminBanner";
import AdminCoupon from "./page/admin/AdminCoupon";
import AdminLogonav from "./page/admin/AdminLogonav";
import LabCartCompo from "./components/LabCartCompo";
import ProductCartCompo from "./components/ProductCartCompo";
import AdminCharges from "./page/admin/AdminCharges";
import AdminNewSubCatagory from "./page/admin/AddNewSubCatagory";
import EditProduct from "./page/admin/EditProduct";
import EditDoc from "./page/admin/EditDoc";
import EditTest from "./page/admin/EditTest";
import LabOrder from "./page/LabOrder";
import AdminCompanyInfo from "./page/admin/AdminCompanyInfo";
import MedicineCartCompo from "./components/MedicineCartCompo";
import OrderedProduct from "./page/OrderedProduct";
import OrderedTest from "./page/OrderedTest";
import PhoneNumberVerify from "./page/PhoneNumberVerify";
import OrderedAppointment from "./page/OrderedAppointment";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  async function getUser() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/auth/get-user`, {
        withCredentials: true,
      });
      dispatch(login({ userData: res.data?.data }));
    } catch (error) {
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  //console.log(userData);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Landing />} />
          <Route path="product/:productId" element={<Product />} />
          <Route
            path="medicines"
            element={
              <Medicines
                apiRoute={`${SERVER_URL}/api/v1/products/get-medicines`}
              />
            }
          />
          <Route
            path="products"
            element={
              <Medicines
                apiRoute={`${SERVER_URL}/api/v1/products/get-products`}
              />
            }
          />
          <Route
            path="products/catagory/:catagory"
            element={
              <Medicines
                apiRoute={`${SERVER_URL}/api/v1/products/catagories`}
              />
            }
          />
          <Route
            path="products/subcatagory/:subcatagory"
            element={
              <Medicines
                apiRoute={`${SERVER_URL}/api/v1/products/subcatagories`}
              />
            }
          />
          <Route
            path="products/brands/:brandName"
            element={
              <Medicines apiRoute={`${SERVER_URL}/api/v1/products/brands`} />
            }
          />
          <Route path="product" element={<Product />} />
          <Route path="doctors" element={<Doctors />} />
          <Route path="lab" element={<LabTest />} />
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="my-order" element={<MyOrder />} />
          <Route path="my-lab-order" element={<LabOrder />} />
          <Route path="my-lab-cart" element={<LabCartCompo />} />
          <Route path="my-product-cart" element={<ProductCartCompo />} />
          <Route path="my-medicine-cart" element={<MedicineCartCompo />} />
          <Route path="my-appointments" element={<MyAppointments />} />
          <Route path="ordered-product/:productId" element={<OrderedProduct />} />
          <Route path="ordered-tests/:productId" element={<OrderedTest />} />
          <Route path="ordered-appointment/:productId" element={<OrderedAppointment />} />
        </Route>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<PhoneNumberVerify />} />
        <Route
          path="/signup/verify-otp"
          element={
            <VerifyOtp apiRoute={`${SERVER_URL}/api/v1/auth/verify-otp`} />
          }
        />
        <Route path="/admin/" element={<AdminLayout/>}>
          <Route path="" element={<AdminLanding />} />
          <Route path="products" element={<AdminProducts />} />
          <Route path="product-form" element={<ProductForm />} />
          <Route path="admin-doctors" element={<AdminDoctors />} />
          <Route path="doctors-form" element={<AddNewDoc />} />
          <Route path="admin-tests" element={<AdminLabTests />} />
          <Route path="labtest-form" element={<AddNewTest />} />
          <Route path="scheduled-tests" element={<ScheduledTest />} />
          <Route path="scheduled-appointments" element={<ScheduledAppointments />} />
          <Route path="due-orders" element={<DueOrders />} />
          <Route path="all-ordered-tests" element={<AllOrderedTests />} />
          <Route path="all-appointments" element={<AllAppointments />} />
          <Route path="all-orders" element={<AllOrders />} />
          <Route path="admin-brand" element={<AdminBrands />} />
          <Route path="admin-product-catagory" element={<AdminProductCatagory />} />
          <Route path="admin-prescriptions" element={<AdminPrescriptions />} />
          <Route path="admin-pincode" element={<AdminPincode />} />
          <Route path="admin-banner" element={<AdminBanner />} />
          <Route path="admin-coupons" element={<AdminCoupon />} />
          <Route path="admin-logonav" element={<AdminLogonav />} />
          <Route path="admin-charges" element={<AdminCharges />} />
          <Route path="admin-product-subcatagories" element={<AdminNewSubCatagory />} />
          <Route path="admin-edit-product" element={<EditProduct />} />
          <Route path="admin-edit-doctor" element={<EditDoc />} />
          <Route path="admin-edit-labtest" element={<EditTest />} />
          <Route path="admin-company-info" element={<AdminCompanyInfo />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
