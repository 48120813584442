import React, { useState } from "react";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Heading2 from "./Heading2";
import AlertBox from "./AlertBox";

function DoctorPaymentButton({
  doctor,
  fees,
  coupon,
  discount,
  patient,
  appointmentDate,
  appointmentPlace,
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function makePayment() {
    if (!isAuthenticated) {
      navigate("/signin")
      return null
    }
    if (Object.keys(patient).length === 0 || !appointmentDate) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select appointment date and patient");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/doctor/checkout`,
        method: "post",
        data: {
          doctor,
          fees,
          coupon,
          discount,
          patient,
          appointmentDate,
          appointmentPlace,
        },
        withCredentials: true,
      });
      console.log(res.data.data);

      const order = res.data.data;

      var options = {
        key: import.meta.env.VITE_RAZORPAY_TESTING_KEY_ID,
        amount: order?.amount_due,
        currency: "INR",
        name: "Appnest Pathology",
        description: "Doctor Appointment",
        image: logo,
        order_id: order?.id,
        callback_url: `${SERVER_URL}/api/v1/payment/doctor/payment-verification`,
        prefill: {
          name: `${userData?.firstName} ${userData?.LastName}`,
          email: userData?.email,
          contact: userData?.mobileNumber,
        },
        notes: {
          address: "Appnest Pathology Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //console.log(window);

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Button className="w-full" onClick={makePayment}>
        Pay Now
      </Button>
    </>
  );
}

export default DoctorPaymentButton;
