import React, { useState } from "react";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "./AlertBox";
import { resetLabNumber } from "@/store/cartNumber.slice";

function LabPayment({
  tests,
  totalPrice,
  coupon,
  discount,
  address,
  patient,
  testDate,
  place,
  charge,
  chargeAmount
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  async function makePayment() {
    if (!userData) {
      navigate("/signin")
      return null
    }
    if (totalPrice === 0 || Object.keys(address).length === 0 || Object.keys(patient).length === 0 || testDate?.length === 0 || place?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select patient, address, test date and test place");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/lab/checkout`,
        method: "post",
        data: {
          tests,
          totalPrice,
          coupon,
          discount,
          address,
          patient,
          testDate,
          place,
          charge,
          chargeAmount
        },
        withCredentials: true,
      });
      console.log(res.data.data);

      const order = res.data.data;
      dispatch(resetLabNumber())

      var options = {
        key: import.meta.env.VITE_RAZORPAY_TESTING_KEY_ID,
        amount: order?.amount_due,
        currency: "INR",
        name: "Appnest Pathology",
        description: "Buying medical products",
        image: logo,
        order_id: order?.id,
        callback_url: `${SERVER_URL}/api/v1/payment/lab/payment-verification`,
        prefill: {
          name: `${userData?.firstName} ${userData?.LastName}`,
          email: userData?.email,
          contact: userData?.mobileNumber,
        },
        notes: {
          address: "Appnest Pathology Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //console.log(window);

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Button className="w-full" onClick={makePayment}>
        Pay Now
      </Button>
    </>
  );
}

export default LabPayment;
