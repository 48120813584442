import React, { useState } from "react";
import { Button } from "./ui/button";
import { BsCartPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { BsFillCartPlusFill } from "react-icons/bs";
import { BsFillCartCheckFill } from "react-icons/bs";
import { increaseProductNumber, increaseMedicineNumber } from "@/store/cartNumber.slice";

function AddToCart({ productId, groupIndex, price, type, ...props }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isAdded, setIsAdded] = useState(false)
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const dispatch = useDispatch()

  //console.log(type);
  

  async function addToCart() {
    if (!isAuthenticated) {
      return navigate("/signin");
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/product/add`,
        method: "post",
        data: {
          productId,
          groupIndex,
          price,
          productType: type
        },
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        if(type === "product") {
          dispatch(increaseProductNumber())
        } else {
          dispatch(increaseMedicineNumber())
        }
        setIsAdded(true)
        setOpen(true);
        setSuccess(true);
        console.log(res.data);
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Added to cart"}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Adding to cart failed"}
        />
      )}
      <Button onClick={addToCart} className="w-full flex items-center gap-1" {...props}>
        {
          isAdded === true ? (<p className="flex items-center gap-2 font-semibold"><BsFillCartCheckFill size={"20px"} />
        Added</p>) : (<p className="flex items-center gap-2 font-semibold"><BsFillCartPlusFill size={"20px"} />
        Add To Cart</p>)
        }
      </Button>
    </>
  );
}

export default AddToCart;
