import React, { useState } from "react";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Heading2 from "./Heading2";
import AlertBox from "./AlertBox";

function DoctorPaymentButton({
  doctor,
  fees,
  coupon,
  discount,
  patient,
  appointmentDate,
  appointmentPlace,
}) {
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  async function makePayment() {
    if (!isAuthenticated) {
      navigate("/signin")
      return null
    }
    if(Object.keys(patient).length === 0 || !appointmentDate) {
        setOpen(true)
        setSuccess(false)
        setMessage("Select patient and appointment date")
        return null
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/doctor/cash-on-delivary`,
        method: "post",
        data: {
          doctor,
          fees,
          coupon,
          discount,
          patient,
          appointmentDate,
          appointmentPlace,
        },
        withCredentials: true,
      });
      navigate("/my-appointments");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <Button className="w-full" onClick={makePayment}>
        Pay At Hospital
      </Button>
    </>
  );
}

export default DoctorPaymentButton;
