import Heading2 from "@/components/Heading2";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function OrderedTest() {
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/ordered/test/get/${productId}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setProductData(res.data.data[0]);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(productData);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[90vw] sm:w-[40rem]">
        <Heading2>
          Booked{" "}
          {productData?.tests?.length > 1
            ? `${productData?.tests?.length} tests`
            : `${productData?.tests?.length} test`}
        </Heading2>
        <section>
          <p>Booking date : {productData?.date?.split("T")[0]}</p>
          <p>
            Status :{" "}
            {productData?.testStatus === "completed" ? (
              <span className="text-green-600 font-semibold">
                {productData?.testStatus?.toUpperCase()}
              </span>
            ) : (
              <span className="text-red-600 font-semibold">
                {productData?.testStatus === "initiated" ? "Confirmed" : productData?.testStatus}
              </span>
            )}
          </p>
        </section>
        {productData?.report?.length > 0 ? (
          <Link
            to={`${SERVER_URL}/api/v1/fetch/image/${productData?.report}`}
            target="_blank"
          >
            <section className="flex items-center justify-between w-full border mt-5 px-5 py-3 rounded-lg">
              <p>Download Report</p>
              <IoIosArrowForward />
            </section>
          </Link>
        ) : null}
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">Tests</p>
          <div
            className="w-full flex flex-col gap-2 justify-start items-center mt-3 border p-5 rounded-lg"
            style={{ scrollbarWidth: "none" }}
          >
            {productData?.tests?.map((e, i) => (
              <>
                <section className="flex justify-between items-start w-full flex-shrink-0">
                  <p className="font-medium">{e?.labtest?.title}</p>
                  <div className="text-right">
                    <p className="font-medium">
                      {e?.labtest?.includeTests?.length} Included tests
                    </p>
                    <p>QTY : {e?.quantity}</p>
                    <p>Rs. {e?.price}</p>
                  </div>
                </section>
              </>
            ))}
          </div>
        </div>
        <p className="mt-5 font-medium">
          Payment Method:{" "}
          <span className="text-slate-600 font-medium">
            {productData?.paymentMethod === "offline" ? "Pay At Lab" : "Card/Netbanking/Wallet"}
          </span>
        </p>
        <p className="mt-1 font-medium">
          Payment Status:{" "}
          <span className="text-slate-500 font-medium">
            {productData?.paymentStatus === "due" ? "Payment Pending" : (productData?.paymentStatus === "success" ? "Payment Recived" : "Payment Canceled")}
          </span>
        </p>
        <div className="w-full flex flex-col justify-center items-center mt-5">
          <p className="w-full text-left text-[18px] font-semibold">
            Test Details
          </p>
          <div className="w-full mt-3 border p-3 rounded-lg">
            <div className="text-left">
              <p>
                Appointment Date :{" "}
                {new Date(productData?.testDate)
                  .toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                  .replace(/,/g, "")}
              </p>
              <p>Test Place : {productData?.place}</p>
              <p>
                {productData?.patient?.firstName +
                  " " +
                  productData?.patient?.lastName}
              </p>
              <p>DOB: {productData?.patient?.dob}</p>
              <p>{productData?.address?.address}</p>
              <p>Pincode : {productData?.address?.pincode}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-5 mb-10 items-center">
          <p className="text-[25px] font-semibold">Order Total</p>
          <p className="text-red-600 font-medium text-[18px]">
            Rs. {productData?.totalPrice}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderedTest;
