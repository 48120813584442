import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import Loading from "@/components/Loading";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Heading from "@/components/Heading";

function PhoneNumberVerify() {
  const { register: phoneRegister, handleSubmit: phoneHandleSubmit } =
    useForm();
  const { register: otpRegister, handleSubmit: otpHandleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordForm, setPasswordForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function sendOtp(data) {
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/auth/generate-forgot-password-otp`,
        method: "post",
        data: data,
      });
      setPasswordForm(true);
      setPhoneNumber(data?.mobileNumber);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account not found");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function changePassword(data) {
    if (data?.newPassword != data?.confirmPassword) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Password doesn't match");
      return null;
    }
    const finalData = {
      mobileNumber: phoneNumber,
      otp: data?.otp,
      newPassword: data?.newPassword,
    };
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/auth/verify-forgot-password-otp`,
        method: "post",
        data: finalData,
      });
      setPasswordForm(true)
      navigate("/signin")
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  if (passwordForm === true) {
    return (
      <div className="w-full flex flex-col justify-center items-center gap-5 mt-[2rem] px-10">
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={errorMessage}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            sendOtp
            title={errorMessage}
          />
        )}
        <img src={logo} className="w-[10rem]" alt="" />
        <section className="text-center mt-[3rem]">
          <Heading>Change Password</Heading>
          <p className={"text-[15px] text-slate-500 mt-5"}>
            Enter OTP and change the password
          </p>
        </section>
        <form
          className="w-full flex flex-col justify-center items-center gap-5"
          onSubmit={otpHandleSubmit(changePassword)}
        >
          <InputField
            label={"Enter OTP"}
            placeholder={"E.g, 9587456983"}
            type={"text"}
            {...otpRegister("otp")}
          />
          <InputField
            label={"New Password"}
            placeholder={"E.g, 9587456983"}
            type={"password"}
            {...otpRegister("newPassword")}
          />
          <InputField
            label={"Confirm Password"}
            placeholder={"E.g, 9587456983"}
            type={"password"}
            {...otpRegister("confirmPassword")}
          />
          {loading === true ? (
            <Loading color={"bg-sky-500"} />
          ) : (
            <Button>Update</Button>
          )}
        </form>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-5 mt-[2rem] px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <img src={logo} className="w-[10rem]" alt="" />
      <section className="text-center mt-[8rem]">
        <Heading>Forgot Password</Heading>
        <p className={"text-[15px] text-slate-500 mt-5"}>
          Please enter your valid phone number along with country code to verify
          your phone number.
        </p>
      </section>
      <form
        className="w-full flex flex-col justify-center items-center gap-5"
        onSubmit={phoneHandleSubmit(sendOtp)}
      >
        <InputField
          label={"Mobile Number"}
          placeholder={"E.g, 9587456983"}
          type={"text"}
          {...phoneRegister("mobileNumber")}
        />
        {loading === true ? (
          <Loading color={"bg-sky-500"} />
        ) : (
          <Button>Send OTP</Button>
        )}
      </form>
    </div>
  );
}

export default PhoneNumberVerify;
