import React, { useEffect, useRef, useState } from "react";
import { HeartPulse, Eye, Smile, Ambulance } from "lucide-react";
import { IoIosArrowForward, IoMdStar } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";
import { FaTooth } from "react-icons/fa";
import { IoFemale } from "react-icons/io5";
import { FaStethoscope } from "react-icons/fa";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import InputField from "@/components/InputField";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { GiBrain } from "react-icons/gi";
import men_icon from "@/assets/man.png";
import { handleMouseDown } from "@/utils/mouseScroll";
import DialogBox from "@/components/DialogBox";
import SelectDate from "@/components/SelectDate";
import SelectField from "@/components/SelectField";
import HorizontalCatagoryBar from "@/components/HorizontalCatagoryBar";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import { useSelector } from "react-redux";
import DoctorPaymentButton from "@/components/DoctorPaymentButton";
import DoctorCodButton from "@/components/DoctorCodButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";
import AddPatient from "@/components/AddPatient";
import UploadPrescription from "@/components/UploadPrescription";

function Doctors() {
  const scrollRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [productData, setProductData] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const userData = useSelector((state) => state.auth?.userData);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [amount1, setAmount1] = useState("");
  const [amount2, setAmount2] = useState("");
  const [city, setCity] = useState("");
  const [experience1, setExperience1] = useState("");
  const [experience2, setExperience2] = useState("");
  const [reviewReload, setReviewReload] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [message, setMessage] = useState("");
  const [searchparams] = useSearchParams();

  const searchId = searchparams.get("id");

  let apiRoute = `${SERVER_URL}/api/v1/doctors/doctor?amount1=${amount1}&amount2=${amount2}&city=${city}&experience1=${experience1}&experience2=${experience2}`;

  if (searchId?.length > 0) {
    apiRoute = `${SERVER_URL}/api/v1/doctors/get/doctor/${searchId}`;
  }

  function getNextWeekdayDate(weekday) {
    const today = new Date();
    const currentDay = today.getDay(); // Current day of the week (0-6)

    // Calculate the number of days to add to get the next target weekday
    let daysToAdd = (weekday + 7 - currentDay) % 7;

    // If it's the same day, go to the next occurrence of that weekday
    if (daysToAdd === 0) {
      daysToAdd = 7;
    }

    // Add the days to the current date
    const nextWeekday = new Date(today);
    nextWeekday.setDate(today.getDate() + daysToAdd);

    const splitedDate = nextWeekday.toString().split(" ");
    const newDate =
      splitedDate[0] +
      " " +
      splitedDate[1] +
      " " +
      splitedDate[2] +
      " " +
      splitedDate[3];
    return newDate;
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: apiRoute,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);

      let rawData = [];
      if (Array.isArray(res.data.data)) {
        rawData = res.data.data;
      } else {
        rawData.push(res.data.data);
      }
      rawData.map((e) => {
        e?.availableDates?.map((j) => {
          console.log(getNextWeekdayDate(j?.weekday));
          j.weekday = getNextWeekdayDate(j?.weekday);
        });
      });
      console.log(rawData);
      setProductData(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addReview(id) {
    console.log(selectedRating);
    if (selectedRating == 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please give a rating");
      return null;
    }
    if (selectedRating < 0 || selectedRating > 5) {
      setOpen(true);
      setSuccess(false);
      setMessage("Give rating within 1 to 5");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/review/doctor/add`,
        method: "post",
        data: {
          rating: Math.ceil(Number(selectedRating)),
          feedback: selectedFeedback,
          reviewFor: id,
        },
        withCredentials: true,
      });
      setReviewReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Review added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Review add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteReview(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/review/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      setReviewReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Review deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Review delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagory() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/catagory`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchByCatagory(catagory) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/catagory?catagory=${catagory}`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res);
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }

    //console.log(catagory);
  }

  function changeExperience(a, b) {
    setExperience1(a);
    setExperience2(b);
  }

  function changeFees(a, b) {
    setAmount1(a);
    setAmount2(b);
    console.log(a);
    console.log(b);
  }

  function clearAllFilter() {
    setAmount1("");
    setAmount2("");
    setExperience1("");
    setExperience2("");
    setCity("");
  }

  useEffect(() => {
    fetchData();
  }, [
    city,
    amount1,
    amount2,
    experience1,
    experience2,
    reviewReload,
    apiRoute,
    searchId,
  ]);

  useEffect(() => {
    fetchCatagory();
  }, []);

  console.log(apiRoute);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] xl:w-[80rem] flex flex-col justify-center items-center">
        <HorizontalCatagoryBar
          catagories={catagories}
          fetchByCatagory={fetchByCatagory}
        />
        <div className="w-full flex justify-end items-end mt-5 2xl:hidden">
          <DialogBox title={"Filter"}>
            <div className="flex flex-col gap-3">
              <div className="flex justify-center items-center gap-2">
                <InputField
                  placeholder="Search By City"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="space-y-3">
                <p className="text-[18px] font-semibold">
                  Experience <span className="text-slate-500">(in Years)</span>
                </p>
                <div>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="apple"
                      onChange={() => changeExperience(0, 5)}
                    />
                    0-5
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="banana"
                      onChange={() => changeExperience(5, 10)}
                    />
                    5-10
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="orange"
                      onChange={() => changeExperience(10, 15)}
                    />
                    10-15
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="grape"
                      onChange={() => changeExperience(15, 10000)}
                    />
                    15-above
                  </label>
                </div>
              </div>
              <div className="space-y-3">
                <p className="text-[18px] font-semibold">
                  Fees <span className="text-slate-500">(in rupees)</span>
                </p>
                <div>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="apple"
                      onChange={() => changeFees(100, 500)}
                    />
                    100-500
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="banana"
                      onChange={() => changeFees(500, 1000)}
                    />
                    500-1000
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="orange"
                      onChange={() => changeFees(1000, 1500)}
                    />
                    1000-1500
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="grape"
                      onChange={() => changeFees(1500, 10000)}
                    />
                    1500-above
                  </label>
                </div>
              </div>
            </div>
          </DialogBox>
        </div>
        <div className="w-full flex mt-5">
          <div className="w-[20vw] hidden 2xl:flex flex-col border-[1px] p-3 pr-2 fixed rounded-lg">
            <div className="w-full flex justify-between">
              <Heading2>Filter</Heading2>
              <Button variant="ghost" onClick={clearAllFilter}>
                Clear All
              </Button>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex justify-center items-center gap-2">
                <InputField
                  placeholder="Search By City"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="space-y-3">
                <p className="text-[18px] font-semibold">
                  Experience <span className="text-slate-500">(in Years)</span>
                </p>
                <div>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="apple"
                      onChange={() => changeExperience(0, 5)}
                    />
                    0-5
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="banana"
                      onChange={() => changeExperience(5, 10)}
                    />
                    5-10
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="orange"
                      onChange={() => changeExperience(10, 15)}
                    />
                    10-15
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="grape"
                      onChange={() => changeExperience(15, 10000)}
                    />
                    15-above
                  </label>
                </div>
              </div>
              <div className="space-y-3">
                <p className="text-[18px] font-semibold">
                  Fees <span className="text-slate-500">(in rupees)</span>
                </p>
                <div>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="apple"
                      onChange={() => changeFees(100, 500)}
                    />
                    100-500
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="banana"
                      onChange={() => changeFees(500, 1000)}
                    />
                    500-1000
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="orange"
                      onChange={() => changeFees(1000, 1500)}
                    />
                    1000-1500
                  </label>
                  <label className="flex gap-2">
                    <input
                      type="radio"
                      name="fruit"
                      value="grape"
                      onChange={() => changeFees(1500, 10000)}
                    />
                    1500-above
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full 2xl:w-[65vw] ml-0 2xl:ml-[22vw] flex flex-col gap-3">
            {productData &&
              productData?.map((e, i) => (
                <section className="w-full border rounded-lg py-3 px-3 flex flex-col sm:flex-row justify-start items-start gap-3">
                  <div className="w-[8rem] h-[7rem] rounded-full">
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.picture}`}
                      className="w-full h-full object-cover rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="w-full flex justify-start items-start md:justify-end md:items-end flex-col md:flex-row">
                    <div className="w-full flex flex-col justify-start items-start">
                      <p className="text-[17px] font-bold">Dr. {e?.name}</p>
                      <p className="text-[14px] font-semibold text-slate-500 mt-1">
                        {e?.designation}
                      </p>
                      <div className="flex items-center gap-2 mt-2">
                        <p className="bg-green-600 w-[3rem] rounded-md text-center flex items- justify-center px-2 text-white text-[13px] py-[1px] font-medium">
                          {Math.round(Number(e?.avgRating) * 10) / 10}{" "}
                          <IoMdStar size={"18px"} />
                        </p>
                        <span>•</span>
                        <DialogBox
                          title={`${
                            e?.reviewed != undefined ? `${e?.reviewed}` : "0"
                          } People Reviewed`}
                          buttonClass={
                            "h-auto p-0 m-0 border-none text-slate-500"
                          }
                        >
                          <div className="flex flex-col gap-3">
                            {e?.review &&
                              e?.review.map((j, i) => (
                                <section
                                  className="bg-green-50 px-5 py-3 rounded-lg"
                                  key={i}
                                >
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[15px]">
                                      {j?.userName?.slice(0, 20) + "..."}
                                    </p>
                                    {j.userId === userData?._id ? (
                                      <DialogClose asChild>
                                        <Button
                                          variant="ghost"
                                          onClick={() => deleteReview(j?._id)}
                                        >
                                          <MdDelete className="text-[20px] text-red-500" />
                                        </Button>
                                      </DialogClose>
                                    ) : null}
                                  </div>
                                  <p className="flex items-center text-green-600 text-[14px] mt-1 mb-1 font-medium">
                                    {Array(j?.rating)
                                      .fill(0)
                                      .map((_, i) => (
                                        <IoMdStar size={"18px"} key={i} />
                                      ))}
                                  </p>
                                  <p className="text-slate-500 mt-1 text-[14px]">
                                    {j?.feedback}
                                  </p>
                                </section>
                              ))}
                            <section className="bg-white w-full py-2 space-y-3">
                              <InputField
                                label="Rate this product 1 to 5"
                                type="number"
                                onChange={(event) =>
                                  setSelectedRating(event.target.value)
                                }
                              />
                              <InputField
                                label="Leave a feedback"
                                onChange={(event) =>
                                  setSelectedFeedback(event.target.value)
                                }
                              />
                              <DialogClose asChild>
                                <Button onClick={() => addReview(e?._id)}>
                                  Submit
                                </Button>
                              </DialogClose>
                            </section>
                          </div>
                        </DialogBox>
                      </div>
                      <p className="text-[13px] font-semibold text-indigo-800 mt-2">
                        {e?.experience} Years • {e?.education}
                      </p>
                      <p className="text-[13px] text-slate-500 mt-1">
                        Reg No: {e?.regNo} • {e?.place}
                      </p>
                      <p className="text-[13px] text-slate-500 mt-1">
                        {e?.workplace}
                      </p>
                    </div>
                    <div className="flex gap-5 flex-col sm:flex-row w-full justify-center">
                      {e?.consultType &&
                        e?.consultType?.map((j, i) => (
                          <div
                            className="shadow-md text-center px-3 py-3 rounded-xl"
                            key={i}
                          >
                            <p className="font-semibold mb-3 text-[17px] text-slate-700">
                              Rs. {j?.charge}
                            </p>
                            <DialogBox
                              title={`At ${j?.place}`}
                              buttonClass={"bg-[#37B7C3] text-white"}
                              className={""}
                            >
                              <div className="w-full flex flex-col gap-0">
                                <p className="mt-0 mb-1 font-medium text-slate-500">
                                  Select Date and Time
                                </p>
                                <SelectDate
                                  dateArray={e?.availableDates}
                                  setSelectedDate={setSelectedDate}
                                  selectedDate={selectedDate}
                                />
                                <p className="mt-5 mb-1 font-medium text-slate-500">
                                  Select Patient
                                </p>
                                <section className="w-full flex justify-start items-start gap-2 mt-1 flex-wrap">
                                  {userData?.patient?.length === 0 && (
                                    <section className="flex justify-between items-center gap-2">
                                      <p>No Patient Found</p>
                                      <AddPatient />
                                    </section>
                                  )}
                                  {userData?.patient?.length > 0 &&
                                    userData?.patient?.map((e, i) => (
                                      <section
                                        className={`border px-3 py-2 cursor-pointer ${
                                          selectedPatient?.firstName ===
                                          e?.firstName
                                            ? "border-blue-500 border-2"
                                            : ""
                                        }`}
                                        onClick={() => setSelectedPatient(e)}
                                      >
                                        <p>{e?.firstName}</p>
                                      </section>
                                    ))}
                                </section>
                                <UploadPrescription doctor={true} />
                                <div className="w-full flex justify-center gap-2 mt-5">
                                  <DoctorPaymentButton
                                    appointmentDate={selectedDate}
                                    appointmentPlace={j?.place}
                                    doctor={e}
                                    fees={j?.charge}
                                    patient={selectedPatient}
                                  />
                                  {j?.consultMode === "offline" ? (
                                    <DoctorCodButton
                                      appointmentDate={selectedDate}
                                      appointmentPlace={j?.place}
                                      doctor={e}
                                      fees={j?.charge}
                                      patient={selectedPatient}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </DialogBox>
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Doctors;
