import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "@/components/Sidebar";
import UploadPrescription from "@/components/UploadPrescription";
import { handleMouseDown } from "@/utils/mouseScroll";
import ProductCard from "@/components/ProductCard";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";

function Medicines({ apiRoute }) {
  const [brand, setBrand] = useState([]);
  const [subCatagories, setSubCatagories] = useState([]);

  const { catagory, brandName, subcatagory } = useParams();

  if (catagory?.length > 0) {
    apiRoute = `${apiRoute}?catagory=${catagory}`;
  }

  if (brandName?.length > 0) {
    apiRoute = `${apiRoute}?brand=${brandName}`;
    console.log(apiRoute);
  }

  if (subcatagory?.length > 0) {
    apiRoute = `${apiRoute}?catagory=${subcatagory}`;
    console.log(apiRoute);
  }

  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [productData, setProductData] = useState([]);

  async function fetchBrands() {
    try {
      setLoading(true);
      const res = await axios.get(`${SERVER_URL}/api/v1/products/brands`);
      //console.log(res.data.data);
      setBrand(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSubcatagories() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/subcatagories`
      );
      //console.log(res.data.data);
      setSubCatagories(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBrands();
    fetchSubcatagories();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: apiRoute,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      <UploadPrescription />;
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [apiRoute]);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Product fetch failed"}
        />
      )}
      <Sidebar catagories={subCatagories} />
      <div className="w-[calc(100%-4rem)] ml-[4rem] px-2">
        <p className="font-medium">Shop By Brand</p>
        <div className="w-full mt-5 flex flex-col">
          <div
            className="flex justify-start items-center gap-5 overflow-auto w-full"
            style={{ scrollbarWidth: "none" }}
            ref={scrollRef}
            onMouseDown={(e) => handleMouseDown(e, scrollRef)}
          >
            {brand &&
              brand.map((e, i) => (
                <Link
                  key={i}
                  to={`/products/brands/${e.name}`}
                  className="flex-shrink-0"
                >
                  <img
                    src={`${SERVER_URL}/api/v1/fetch/image/${e.image}`}
                    className="w-[4rem]"
                    alt=""
                  />
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-start">
        <div className="w-[calc(100%-4rem)] ml-[4rem] mt-10 px-5 flex justify-start items-center">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 2xl:grid-cols-6 gap-2 place-items-center">
            {productData &&
              productData.map((e, i) => <ProductCard key={i} data={e} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medicines;
