import React, { useEffect, useState } from "react";
import vicks from "@/assets/vicks.bmp";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import AlertBox from "@/components/AlertBox";

function MyAppointments() {
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-appointments`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setLabData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(labData);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] md:w-[50rem] flex justify-center items-center flex-col border py-3 rounded-lg">
        {labData?.map((e, i) => (
          <Link className="w-full" to={`/ordered-appointment/${e?._id}`}>
            <section className="flex justify-between items-start w-full border-b-[1px] py-3 px-5 hover:bg-slate-50">
              <div>
                <span className="text-[10px] sm:text-[12px] text-slate-500 bg-slate-200 px-2 rounded-xl">
                  {e?.date?.split("T")[0]}
                </span>
                <p className="font-medium mt-1 text-[13px] sm:text-[15px]">
                  Booked Dr. {
                    e?.doctor?.name
                  }
                </p>
              </div>
              <div>
                <p>
                  {e?.appointmentStatus === "completed" ? (
                    <span className="text-green-600 text-[13px] sm:text-[15px]">
                      Completed
                    </span>
                  ) : (
                    <span>{e?.appointmentStatus?.charAt(0)?.toUpperCase() + e?.appointmentStatus?.slice(1)}</span>
                  )}
                </p>
                <p className="flex items-center gap-3 mt-1 text-[13px] sm:text-[15px] text-slate-500">
                  See More <IoIosArrowForward />
                </p>
              </div>
            </section>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default MyAppointments;
