import Heading from "@/components/Heading";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import doctor from "@/assets/doctorImg.svg";
import truck from "@/assets/truck.jpg";
import banner from "@/assets/banner.jpg";
import charges from "@/assets/charges.jpg";
import prescription from "@/assets/prescriptionImg.svg";
import lab from "@/assets/lab.svg";
import order from "@/assets/order.svg";
import appointment from "@/assets/appointment.svg";
import { IoIosArrowForward } from "react-icons/io";
import { FaCircleUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

function AdminLanding() {
  const userData = useSelector((state) => state.auth?.userData);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dueTests, setDueTests] = useState([]);
  const [dueAppointments, setDueAppointments] = useState([]);
  const [dueOrders, setDueOrders] = useState([]);

  async function fetchOrderData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/products/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueOrders = allData.filter(
        (e) => e?.delivaryStatus === "initiated"
      );
      setDueOrders(dueOrders);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchLabData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/tests/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueTests = allData.filter((e) => e?.testStatus === "initiated");
      setDueTests(dueTests);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchAppointmentData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/appointments/all`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      const dueAppointments = allData.filter(
        (e) => e?.appointmentStatus === "scheduled"
      );
      setDueAppointments(dueAppointments);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchOrderData();
    fetchAppointmentData();
    fetchLabData();
  }, []);

  //console.log(dueOrders);
  console.log(dueTests);
  //console.log(dueAppointments);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-3 gap-3 flex flex-col justify-start items-start">
      <div className="w-full text-center">
        <Heading>Admin Dashboard</Heading>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 place-items-center mt-10 gap-2">
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px] text-left w-full">
            Due Product Orders
          </p>
          <div className="flex gap-3">
            <img src={order} className="w-[5rem]" alt="" />
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueOrders?.length}
            </p>
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/due-orders"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
            <Link
              to={"/admin/all-orders"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              History <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px] text-left w-full">
            Due Appointments
          </p>
          <div className="flex gap-3">
            <img src={appointment} className="w-[5rem]" alt="" />
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueAppointments?.length}
            </p>
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/scheduled-appointments"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
            <Link
              to={"/admin/all-appointments"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              History <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            Due Lab Tests
          </p>
          <div className="flex gap-3">
            <img src={lab} className="w-[5rem]" alt="" />
            <p className="text-[30px] sm:text-[50px] font-bold">
              {dueTests?.length}
            </p>
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/scheduled-tests"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
            <Link
              to={"/admin/all-ordered-tests"}
              className="text-[14px] sm:text-[16px] font-bold flex items-center gap-3 text-sky-500"
            >
              History <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            Get All Doctors
          </p>
          <div className="flex gap-3">
            <img src={doctor} className="w-[5rem]" alt="" />
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/admin-doctors"}
              className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            All Prescriptions
          </p>
          <div className="flex gap-3">
            <img src={prescription} className="w-[5rem]" alt="" />
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/admin-prescriptions"}
              className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            Pincodes
          </p>
          <div className="flex gap-3">
            <img src={truck} className="w-[7rem]" alt="" />
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/admin-pincode"}
              className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            Banners
          </p>
          <div className="flex gap-3">
            <img src={banner} className="w-[7rem]" alt="" />
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/admin-banner"}
              className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
        <section className="border flex flex-col gap-5 h-[13rem] px-5 py-3 rounded-md w-[90vw] sm:w-full justify-between">
          <p className="font-normal text-[14px] sm:text-[16px]">
            Charges
          </p>
          <div className="flex gap-3">
            <img src={charges} className="w-[5rem]" alt="" />
          </div>
          <div className="w-full flex gap-3 flex-wrap">
            <Link
              to={"/admin/admin-charges"}
              className="text-[16px] sm:text-[20px] font-bold flex items-center gap-3 text-sky-500"
            >
              View All <IoIosArrowForward />
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdminLanding;
