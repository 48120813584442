import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { FRONTEND_URL, SERVER_URL } from "@/constant";
import DialogBox from "@/components/DialogBox";
import { Link, useSearchParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function MyOrder() {
  const [productData, setProductData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-orders`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Orders fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] md:w-[50rem] flex justify-center items-center flex-col border py-3 rounded-lg">
        {
          productData?.map((e, i) => (
            <Link className="w-full" to={`/ordered-product/${e?._id}`}>
              <section className="flex justify-between items-start w-full border-b-[1px] py-3 px-5 hover:bg-slate-50">
                <div>
                  <span className="text-[10px] sm:text-[12px] text-slate-500 bg-slate-200 px-2 rounded-xl">{e?.date?.split("T")[0]}</span>
                  <p className="font-medium mt-1 text-[13px] sm:text-[15px]">Ordered{" "}
                    {e?.products?.length > 1
                      ? `${e?.products?.length} items`
                      : `${e?.products?.length} item`}</p>
                </div>
                <div className="">
                  <p>{e?.delivaryStatus === "delivered" ? <span className="text-green-600 text-[13px] sm:text-[15px]">Delivared</span> : <span>{e?.delivaryStatus === "initiated" ? "Confirmed" : e?.delivaryStatus?.charAt(0)?.toUpperCase() + e?.delivaryStatus?.slice(1)}</span>}</p>
                  <p className="flex items-center gap-3 mt-1 text-[13px] sm:text-[15px] text-slate-500 text-right w-full justify-end">See More <IoIosArrowForward /></p>
                </div>
              </section>
            </Link>
          ))
        }
      </div>
    </div>
  );
}

export default MyOrder;
