import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import AlertBox from "@/components/AlertBox";
import { DialogClose } from "@/components/ui/dialog";

function AdminLogonav() {
  const [productsData, setProductsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);
  const [file, setFile] = useState(null);
  const [navTitle, setNavTitle] = useState("");
  const [navLink, setNavLink] = useState("");

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/admin/logonav`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setProductsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function changeStatus(id, status) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/nav-options/change-status/${id}/${status}`,
        method: "put",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Status updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Status update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delProduct(deleteProductId) {
    if (!deleteProductId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    console.log(deleteProductId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/nav-options/delete/${deleteProductId}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addLogo() {
    if (!file) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select logo");
      return null;
    }
    const formData = new FormData();
    formData.append("websiteLogo", file);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/logo/update`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Update failed");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  async function addNavOption() {
    if (navTitle?.length === 0 || navLink?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please choose a title and a link");
      return null;
    }
    console.log(navTitle?.length);
    const formData = new FormData();
    formData.append("navOptionImg", file);
    formData.append("title", navTitle);
    formData.append("link", navLink);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/website/nav-options/add`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  return (
    <div className="w-full p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full border rounded-md p-2 text-center space-y-3">
        <Heading2>Logo and Navigations</Heading2>
        <div className="w-full flex justify-end gap-2">
          <DialogBox title={"Update Logo"}>
            <div className="w-full space-y-3">
              <InputField
                label="Logo"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
              <DialogClose>
                <Button onClick={addLogo}>Save</Button>
              </DialogClose>
            </div>
          </DialogBox>
          <DialogBox title={"Add New Naviagtion"}>
            <div className="w-full space-y-3">
              <InputField
                label="Title"
                onChange={(e) => setNavTitle(e.target.value)}
              />
              <InputField
                label="Link"
                placeholder="E.g, /link"
                onChange={(e) => setNavLink(e.target.value)}
              />
              <InputField
                label="Image"
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
              <DialogClose>
                <Button onClick={addNavOption}>Save</Button>
              </DialogClose>
            </div>
          </DialogBox>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-left">Image</TableHead>
              <TableHead className="text-center">Title</TableHead>
              <TableHead className="text-center">Type</TableHead>
              <TableHead className="text-center">Link</TableHead>
              <TableHead className="text-center">Status</TableHead>
              <TableHead className="text-right">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {productsData &&
              productsData?.map((e, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <img
                      src={`${SERVER_URL}/api/v1/fetch/image/${e?.image}`}
                      alt=""
                      className="w-[3rem]"
                    />
                  </TableCell>
                  <TableCell>{e?.title}</TableCell>
                  <TableCell>{e?.type}</TableCell>
                  <TableCell>{e?.link}</TableCell>
                  <TableCell>
                    {e?.type === "logo"
                      ? e?.isActive
                        ? "Active"
                        : "Inactive"
                      : ""}
                    {e?.type != "logo" &&
                      (e?.isActive === true ? (
                        <Button onClick={() => changeStatus(e?._id, false)}>
                          Active
                        </Button>
                      ) : (
                        <Button
                          variant="destructive"
                          onClick={() => changeStatus(e?._id, true)}
                        >
                          Inactive
                        </Button>
                      ))}
                  </TableCell>
                  <TableCell className="text-right">
                    {e?.type === "logo" && (
                      <Button
                        variant="destructive"
                        onClick={() => delProduct(e?._id)}
                        disabled
                      >
                        Delete
                      </Button>
                    )}
                    {e?.type != "logo" && (
                      <Button
                        variant="destructive"
                        onClick={() => delProduct(e?._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default AdminLogonav;
