import UploadPrescription from "@/components/UploadPrescription";
import React, { useEffect, useState } from "react";
import HorizontalCatagoryBar from "@/components/HorizontalCatagoryBar";
import LabProduct from "@/components/LabProduct";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

function LabTest() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [productData, setProductData] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [searchparams] = useSearchParams();

  const searchId = searchparams.get("id");

  let apiRoute = `${SERVER_URL}/api/v1/lab-test/all`;

  if (searchId?.length > 0) {
    apiRoute = `${SERVER_URL}/api/v1/lab-test/get/test/${searchId}`;
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: apiRoute,
        method: "get",
        withCredentials: true,
      });
      let prodArray = [];
      if (!Array.isArray(res.data.data)) {
        prodArray.push(res.data.data);
        setProductData(prodArray);
      } else {
        setProductData(res.data.data);
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagory() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchByCatagory(catagory) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/lab-test/catagory?catagory=${catagory}`,
        method: "get",
        withCredentials: true,
      });
      setProductData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchCatagory();
  }, []);

  console.log(productData);

  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="w-[90vw] flex flex-col justify-center items-center">
        <UploadPrescription />
        <HorizontalCatagoryBar
          catagories={catagories}
          fetchByCatagory={fetchByCatagory}
        />
        <div className="w-full m-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 2xl:grid-cols-6 gap-2 place-items-center">
          {productData &&
            productData?.map((e, i) => (
              <LabProduct
                title={e?.title}
                discount={e?.discount}
                mrp={e?.mrp}
                price={e?.price}
                testArray={e?.includeTests}
                key={i}
                id={e?._id}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default LabTest;
