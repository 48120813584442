import React, { useEffect, useState } from "react";
import vicks from "@/assets/vicks.bmp";
import { Button } from "./ui/button";
import CardComponent from "./CardComponent";
import InputField from "./InputField";
import DialogBox from "./DialogBox";
import AddPatient from "./AddPatient";
import AddAddress from "./AddAddress";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import Heading from "./Heading";
import AlertBox from "./AlertBox";
import { useSelector } from "react-redux";
import PaymentButton from "./PaymentButton";
import ProductCodButton from "./ProductCodButton";
import Heading2 from "./Heading2";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaMinus, FaPlus } from "react-icons/fa6";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { LiaCartArrowDownSolid } from "react-icons/lia";

function ProductCartCompo() {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [toBePaid, setToBePaid] = useState(0);
  const userData = useSelector((state) => state.auth?.userData);
  const [allCoupons, setAllCoupons] = useState([]);
  const [discountType, setDiscountType] = useState(null);
  const [charges, setCharges] = useState([]);
  const [appliedCharges, setAppliedCharges] = useState([]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [couponMessage, setCouponMessage] = useState("");
  const [isAvailablePincode, setIsAvailablePincode] = useState(undefined);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/products/product`,
        method: "get",
        withCredentials: true,
      });
      const allData = res.data.data;
      allData.map((e, i) => {
        e.price = e?.price * e?.quantity;
      });
      let totalAmount = 0;
      allData?.map((e) => {
        totalAmount = totalAmount + e?.price;
      });
      setProductData(res.data.data);
      setSelectedItem(res.data.data);
      setTotalPrice(totalAmount);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCharges() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/charges/client/get`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setCharges(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function checkPincode(address) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/pincode/client/pincode/${address?.pincode}`,
        method: "get",
      });
      setIsAvailablePincode(true);
      setSelectedAddress(address);
    } catch (error) {
      setIsAvailablePincode(false);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCoupons() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/client/get/product`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data.data);
      setAllCoupons(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteProduct(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/product/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      //console.log(res);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Medicine deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Medicine delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteProductQuantity(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/product/quantity/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      //console.log(res);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Medicine deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Medicine delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function applyCoupon() {
    //console.log(selectedCoupon);
    if (selectedCoupon.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please enter a coupon");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/coupon/get/${selectedCoupon}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      if (res.data.data?.couponFor != "product") {
        setAppliedCoupon(false);
        setCouponMessage("This coupon is not for medicines");
        return null;
      }
      setCouponDiscount(res.data.data.discount);
      setDiscountType(res.data.data.discountType);
      setAppliedCoupon(true);
      setCouponMessage("Coupon applied");
    } catch (error) {
      setAppliedCoupon(false);
      setCouponMessage("Coupon apply failed");
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (discountType === "percentage") {
      setToBePaid(Math.ceil(Number(totalPrice - (couponDiscount / 100) * totalPrice)));
    } else {
      if (couponDiscount < totalPrice) {
        setToBePaid(Math.ceil(Number(totalPrice - couponDiscount)));
      } else {
        if (couponDiscount > 0) {
          setOpen(true);
          setSuccess(false);
          setMessage("Total amount must me bigger than coupon discount");
        }
        setCouponDiscount(0);
        setToBePaid(Math.ceil(Number(totalPrice)));
      }
    }
  }, [totalPrice, couponDiscount]);

  useEffect(() => {
    let validCharges = [];
    let chargeAmount = 0;

    charges.forEach((e) => {
      if (e?.minimumAmount > totalPrice) {
        validCharges.push(e);
        chargeAmount += e?.chargeAmount; // Accumulate chargeAmount
      }
    });

    setAppliedCharges(validCharges);
    setTotalCharge(chargeAmount);

    setToBePaid((prev) => Math.ceil(Number(prev + chargeAmount)));
  }, [totalPrice, couponDiscount]);

  async function addToCart(productId, groupIndex, price) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/cart/product/add`,
        method: "post",
        data: {
          productId,
          groupIndex,
          price,
        },
        withCredentials: true,
      });
      if (res.status >= 200 || res.status < 400) {
        setReload((prev) => !prev);
        setOpen(true);
        setSuccess(true);
        setMessage("Added");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchCoupons();
    fetchCharges();
  }, [reload]);

  console.log(productData);

  if (productData?.length === 0) {
    return (
      <div className="w-full flex justify-center items-center h-[70vh] flex-col">
        <Heading className="text-slate-500 text-[20px] sm:text-[30px] font-semibold">Your cart is empty</Heading>
        <LiaCartArrowDownSolid className="text-slate-500 text-[3rem] sm:text-[7rem]" />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Heading2>Product Cart</Heading2>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[80vw] flex justify-center gap-5 mt-3">
        <div className="w-full lg:w-1/2 space-y-3">
          <div
            className="w-full h-[70vh] space-y-3"
            style={{ scrollbarWidth: "none" }}
          >
            {productData &&
              productData.map((e, i) => (
                <section
                  className="flex flex-col w-full border items-start p-3 rounded-lg"
                  key={i}
                >
                  <div className="w-full flex justify-between items-center mb-2 border-b-[1px] pb-2">
                    <div>
                      <p className="bg-green-500 px-2 rounded-md text-white text-[13px] font-semibold">
                        {e?.product?.type?.toUpperCase()}
                      </p>
                    </div>
                    <Button
                      variant="destructive"
                      className="h-[2rem]"
                      onClick={() => deleteProduct(e?._id)}
                    >
                      Remove
                    </Button>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <img
                        src={`${SERVER_URL}/api/v1/fetch/image/${e?.product?.pictures[0]}`}
                        className="w-[7rem]"
                        alt=""
                      />
                    </div>
                    <div className="text-right flex flex-col justify-end items-end">
                      <p className="text-[17px] sm:text-[20px] font-bold">
                        {e?.product?.title}
                      </p>
                      <p className="text-[13px] text-slate-500">
                        {e?.product?.mkt}
                      </p>
                      <p className="text-[13px] font-semibold text-slate-500">
                        {e?.product?.groups[e?.groupIndex]?.title}
                      </p>
                      <p className="text-[20px] font-bold">₹{e?.price}</p>
                      <div className="flex gap-2 items-center border w-auto mt-2 rounded-md">
                        <Button
                          variant="outline"
                          className="h-[2rem] border-none"
                          onClick={() =>
                            addToCart(e?.productId, e?.groupIndex, e?.price)
                          }
                        >
                          <FaPlus className="" />
                        </Button>
                        <p>{e?.quantity}</p>
                        <Button
                          variant="outline"
                          className="h-[2rem] border-none"
                          onClick={() => deleteProductQuantity(e?.productId)}
                        >
                          <FaMinus className="" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </section>
              ))}
          </div>
          <div className="flex lg:hidden fixed bottom-0 bg-white py-3 w-full">
            <DialogBox
              title={"Make A Bill"}
              buttonClass={"w-[80%] bg-[#37B7C3] text-white text-[16px]"}
            >
              <CardComponent width="min-w-[270px]">
                <div>
                  <p className="pt-5 text-[20px] font-semibold mb-5">
                    Bill Summary
                  </p>
                  <div className="w-full space-y-1 border-b-[1px] pb-3">
                    {selectedItem &&
                      selectedItem?.map((e, i) => (
                        <section
                          className="w-full flex justify-between items-center text-[15px] text-slate-500"
                          key={i}
                        >
                          <p>{`${e?.product?.title} x ${e?.quantity}`}</p>
                          <p>₹{e?.price}</p>
                        </section>
                      ))}
                  </div>
                  <div className="w-full space-y-1 mt-3 border-b-[1px] pb-3">
                    <section className="w-full flex justify-between items-center text-[15px]">
                      <p className="font-bold">Total Price</p>
                      <p className="font-bold">₹{totalPrice}</p>
                    </section>
                    {totalPrice > 0 ? (
                      <section className="w-full flex flex-col justify-between items-center text-[15px]">
                        {appliedCharges.map((e, i) => (
                          <div className="w-full flex justify-between">
                            <p>{e?.title}</p>
                            <p>₹{e?.chargeAmount}</p>
                          </div>
                        ))}
                      </section>
                    ) : null}
                    {totalPrice > 0 ? (
                      <section className="w-full flex justify-between items-center text-[15px]">
                        <p>Total Charges</p>
                        <p>₹{totalCharge}</p>
                      </section>
                    ) : null}
                    <section className="w-full flex justify-between items-center text-[15px] text-green-600">
                      <p>Coupon Discount</p>
                      {discountType === "amount"
                        ? `Rs. ${couponDiscount}`
                        : `${couponDiscount}%`}
                    </section>
                  </div>
                  <div className="w-full mt-3">
                    <section className="w-full flex justify-between items-center text-[19px] text-slate-700 border-b-[1px] pb-3">
                      <p className="font-bold">To Be Paid</p>
                      <p className="font-bold">₹{toBePaid}</p>
                    </section>
                    <section className="w-full flex flex-col items-start justify-between gap-2 mt-3">
                      <div className="flex items-end w-full justify-between gap-3">
                        <InputField
                          label="Enter Coupon"
                          onChange={(e) => setSelectedCoupon(e.target.value)}
                        />
                        <Button
                          onClick={() => {
                            applyCoupon();
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                      {appliedCoupon === true ? (
                        <p className="text-green-600 font-medium">
                          {couponMessage}
                        </p>
                      ) : (
                        <p className="text-red-600 font-medium">
                          {couponMessage}
                        </p>
                      )}
                      <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value="item-1">
                          <AccordionTrigger>Coupons</AccordionTrigger>
                          <AccordionContent>
                            {allCoupons?.map((j, i) => (
                              <div className="w-full flex justify-between items-center gap-2">
                                <section className="flex items-center gap-2">
                                  <p>{j?.coupon}</p>
                                  <p>•</p>
                                  <p>
                                    {j?.discountType === "amount"
                                      ? `Rs. ${j?.discount}`
                                      : `${j?.discount}%`}{" "}
                                    off
                                  </p>
                                </section>
                                <Button
                                  variant="ghost"
                                  onClick={() =>
                                    navigator.clipboard.writeText(j?.coupon)
                                  }
                                >
                                  <MdOutlineContentCopy className="text-[20px] text-slate-500" />
                                </Button>
                              </div>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </section>
                    <p className="mt-5 font-semibold">
                      Select Address <span className="text-red-600">*</span>
                    </p>
                    <section className="w-full flex flex-wrap justify-start items-start gap-2 mt-3">
                      {userData?.address?.length === 0 && (
                        <section className="flex justify-between items-center gap-2">
                          <p>No Patient Found</p>
                          <AddAddress />
                        </section>
                      )}
                      {userData?.address?.length > 0 &&
                        userData?.address?.map((e, i) => (
                          <section
                            className={`border px-3 py-2 cursor-pointer ${
                              selectedAddress?.address === e?.address
                                ? "border-blue-500 border-2"
                                : ""
                            }`}
                            onClick={() => {
                              checkPincode(e);
                            }}
                          >
                            <p>{e?.address}</p>
                          </section>
                        ))}
                    </section>
                    {isAvailablePincode === false &&
                    isAvailablePincode != undefined ? (
                      <p className="mt-2 text-red-600">
                        We are not available at this location. We will be
                        available soon.
                      </p>
                    ) : (
                      ""
                    )}
                    {isAvailablePincode && (
                      <section className="w-full flex flex-wrap flex-row gap-2 mt-3">
                        <PaymentButton
                          products={selectedItem}
                          address={selectedAddress}
                          coupon={selectedCoupon}
                          discount={couponDiscount}
                          patient={selectedPatient}
                          totalPrice={toBePaid}
                          charges={appliedCharges}
                          chargeAmount={totalCharge}
                          discountType={discountType}
                        />
                        <ProductCodButton
                          products={selectedItem}
                          address={selectedAddress}
                          coupon={selectedCoupon}
                          discount={couponDiscount}
                          patient={selectedPatient}
                          totalPrice={toBePaid}
                          charges={appliedCharges}
                          chargeAmount={totalCharge}
                          discountType={discountType}
                        />
                      </section>
                    )}
                  </div>
                </div>
              </CardComponent>
            </DialogBox>
          </div>
        </div>
        <div className="w-1/2 hidden lg:flex flex-col items-center">
          <CardComponent width="w-[100%]">
            <div>
              <p className="pt-5 text-[20px] font-semibold mb-5">
                Bill Summary
              </p>
              <div className="w-full space-y-1 border-b-[1px] pb-3">
                {selectedItem &&
                  selectedItem?.map((e, i) => (
                    <section
                      className="w-full flex justify-between items-center text-[15px] text-slate-500"
                      key={i}
                    >
                      <p>{`${e?.product?.title} x ${e?.quantity}`}</p>
                      <p>₹{e?.price}</p>
                    </section>
                  ))}
              </div>
              <div className="w-full space-y-1 mt-3 border-b-[1px] pb-3">
                <section className="w-full flex justify-between items-center text-[15px]">
                  <p className="font-bold">Total Price</p>
                  <p className="font-bold">₹{totalPrice}</p>
                </section>
                {totalPrice > 0 ? (
                  <section className="w-full flex flex-col justify-between items-center text-[15px]">
                    {appliedCharges.map((e, i) => (
                      <div className="w-full flex justify-between">
                        <p>{e?.title}</p>
                        <p>₹{e?.chargeAmount}</p>
                      </div>
                    ))}
                  </section>
                ) : null}
                {totalPrice > 0 ? (
                  <section className="w-full flex justify-between items-center text-[15px]">
                    <p>Total Charges</p>
                    <p>₹{totalCharge}</p>
                  </section>
                ) : null}
                <section className="w-full flex justify-between items-center text-[15px] text-green-600">
                  <p>Coupon Discount</p>
                  {discountType === "amount"
                    ? `Rs. ${couponDiscount}`
                    : `${couponDiscount}%`}
                </section>
              </div>
              <div className="w-full mt-3">
                <section className="w-full flex justify-between items-center text-[19px] text-slate-700 border-b-[1px] pb-3">
                  <p className="font-bold">To Be Paid</p>
                  <p className="font-bold">₹{toBePaid}</p>
                </section>
                <section className="w-full flex flex-col items-start justify-between gap-2 mt-3">
                  <div className="flex items-end w-full justify-between">
                    <InputField
                      label="Enter Coupon"
                      onChange={(e) => setSelectedCoupon(e.target.value)}
                    />
                    <Button
                      onClick={() => {
                        applyCoupon();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                  {appliedCoupon === true ? (
                    <p className="text-green-600 font-medium">
                      {couponMessage}
                    </p>
                  ) : (
                    <p className="text-red-600 font-medium">{couponMessage}</p>
                  )}
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Coupons</AccordionTrigger>
                      <AccordionContent>
                        {allCoupons?.map((j, i) => (
                          <div className="w-full flex justify-between items-center gap-2">
                            <section className="flex items-center gap-2">
                              <p>{j?.coupon}</p>
                              <p>•</p>
                              <p>
                                {j?.discountType === "amount"
                                  ? `Rs. ${j?.discount}`
                                  : `${j?.discount}%`}{" "}
                                off
                              </p>
                            </section>
                            <Button
                              variant="ghost"
                              onClick={() =>
                                navigator.clipboard.writeText(j?.coupon)
                              }
                            >
                              <MdOutlineContentCopy className="text-[20px] text-slate-500" />
                            </Button>
                          </div>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </section>
                <p className="mt-5 font-semibold">
                  Select Your Address First{" "}
                  <span className="text-red-600">*</span>
                </p>
                <section className="w-full flex flex-wrap justify-start items-start gap-2 mt-3">
                  {userData?.address?.length === 0 && (
                    <section className="flex justify-between items-center gap-2">
                      <p>No Patient Found</p>
                      <AddAddress />
                    </section>
                  )}
                  {userData?.address?.length > 0 &&
                    userData?.address?.map((e, i) => (
                      <section
                        className={`border px-3 py-2 cursor-pointer ${
                          selectedAddress?.address === e?.address
                            ? "border-blue-500 border-2"
                            : ""
                        }`}
                        onClick={() => {
                          checkPincode(e);
                        }}
                      >
                        <p>{e?.address}</p>
                      </section>
                    ))}
                </section>
                {isAvailablePincode === false &&
                isAvailablePincode != undefined ? (
                  <p className="mt-2 text-red-600">
                    We are not available at this location. We will be available
                    soon.
                  </p>
                ) : (
                  ""
                )}
                {isAvailablePincode && (
                  <section className="w-full flex flex-row gap-2 mt-3">
                    <PaymentButton
                      products={selectedItem}
                      address={selectedAddress}
                      coupon={selectedCoupon}
                      discount={couponDiscount}
                      patient={selectedPatient}
                      totalPrice={toBePaid}
                      charges={appliedCharges}
                      chargeAmount={totalCharge}
                      discountType={discountType}
                    />
                    <ProductCodButton
                      products={selectedItem}
                      address={selectedAddress}
                      coupon={selectedCoupon}
                      discount={couponDiscount}
                      patient={selectedPatient}
                      totalPrice={toBePaid}
                      charges={appliedCharges}
                      chargeAmount={totalCharge}
                      discountType={discountType}
                    />
                  </section>
                )}
              </div>
            </div>
          </CardComponent>
        </div>
      </div>
    </div>
  );
}

export default ProductCartCompo;
