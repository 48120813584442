import BredcrumbBox from "@/components/BredcrumbBox";
import CardComponent from "@/components/CardComponent";
import Heading from "@/components/Heading";
import { Button } from "@/components/ui/button";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AccrodianBox from "@/components/AccrodianBox";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Heading2 from "@/components/Heading2";
import GroupCarousel from "@/components/GroupCarousel";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import AddToCart from "@/components/AddToCart";
import Loading from "@/components/Loading";
import PincodeCompo from "@/components/PincodeCompo";
import { IoMdStar } from "react-icons/io";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import AlertBox from "@/components/AlertBox";
import { data } from "autoprefixer";

function Product() {
  const [productImg, setProductImg] = useState("");
  const [productData, setProductData] = useState([]);
  const [productType, setproductType] = useState(0);
  const { productId } = useParams();
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [bestsellers, setBestsellers] = useState([]);
  const [imgLoad, setImgLoad] = useState(true);
  const [review, setReview] = useState([]);
  const userData = useSelector((state) => state.auth?.userData);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [reviewReload, setReviewReload] = useState(false);

  async function fetchBestseller() {
    try {
      setLoading(true);
      const res = await axios.get(
        `${SERVER_URL}/api/v1/products/keyword/bestseller`
      );
      // console.log(res.data.data);
      setBestsellers(res.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/products/get-products?productId=${productId}`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data);
      setProductData(res.data.data[0]);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addReview() {
    console.log(selectedRating);
    if (selectedRating == 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please give a rating");
      return null;
    }
    if (selectedRating < 0 || selectedRating > 5) {
      setOpen(true);
      setSuccess(false);
      setMessage("Give rating within 1 to 5");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/review/product/add`,
        method: "post",
        data: {
          rating: selectedRating,
          feedback: selectedFeedback,
          reviewFor: productData?._id,
        },
        withCredentials: true,
      });
      setReviewReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Review added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Review add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteReview(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/review/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      setReviewReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Review deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Review delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchReview() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/review/get/${productId}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setReview(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const productImgArray = productData?.pictures || [];
  const groups = productData?.groups || [];

  const productDetails = [
    {
      title: "Product Details",
      content: productData?.description,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [productId, reviewReload]);

  useEffect(() => {
    fetchReview();
  }, [reviewReload]);

  useEffect(() => {
    setProductImg(productImgArray[0]);
  }, [productImgArray]);

  useEffect(() => {
    fetchBestseller();
  }, []);

  console.log(productData);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] 2xl:w-[95rem] hidden lg:flex">
        <div className="w-full">
          <BredcrumbBox title={productData?.title} />
          <p className="text-[13px] font-semibold text-green-600 mt-3">
            19 people bought in last 90 days
          </p>
          <div className="w-full flex justify-between items-center mt-[1rem] gap-2">
            <div className="w-[40vw] lg:w-[30vw] xl:w-[25vw] flex justify-center items-center">
              <div className="flex flex-col gap-2">
                {productImgArray &&
                  productImgArray.map((e, i) => (
                    <Button
                      className={`w-[5rem] h-[5rem] bg-white border hover:bg-white ${
                        productImg === e ? "border-green-900" : ""
                      }`}
                      key={i}
                      onClick={() => setProductImg(e)}
                    >
                      <img
                        src={`${SERVER_URL}/api/v1/fetch/image/${e}`}
                        className="w-full"
                        alt=""
                      />
                    </Button>
                  ))}
              </div>
              <div>
                <div className="w-[90%] h-[90%]">
                  <Zoom>
                    <img
                      alt="Loading..."
                      src={`${SERVER_URL}/api/v1/fetch/image/${productImg}`}
                      width="500"
                      onLoad={() => setImgLoad(false)}
                    />
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="w-[60vw] xl:w-[40vw] lg:w-[40vw] flex flex-col">
              <Heading className={"text-[27px] font-semibold"}>
                {productData?.title}
              </Heading>
              <div className="flex items-center gap-2">
                <p className="bg-green-600 w-[3rem] rounded-md text-center flex items- justify-center px-2 text-white text-[13px] py-[1px] font-medium">
                  {Math.round(Number(productData?.avgRating) * 10) / 10}{" "}
                  <IoMdStar size={"18px"} />
                </p>
                <span>•</span>
                <DialogBox
                  title={`${
                    review[0]?.totalCount != undefined
                      ? `${review[0]?.totalCount}`
                      : "0"
                  } People Reviewed`}
                  buttonClass={"h-auto p-0 m-0 border-none text-slate-500"}
                >
                  <div className="flex flex-col gap-3">
                    {review.map((e, i) => (
                      <section
                        className="bg-green-50 px-5 py-3 rounded-lg"
                        key={i}
                      >
                        <div className="flex justify-between items-center">
                          <p className="font-medium text-[15px]">
                            {e?.documents?.userName?.slice(0, 20) + "..."}
                          </p>
                          {e?.documents?.userId === userData?._id ? (
                            <Button
                              variant="ghost"
                              onClick={() => deleteReview(e?.documents?._id)}
                            >
                              <MdDelete className="text-[20px] text-red-500" />
                            </Button>
                          ) : null}
                        </div>
                        <p className="flex items-center text-green-600 text-[14px] mt-1 mb-1 font-medium">
                          {Array(e?.documents?.rating)
                            .fill(0)
                            .map((_, i) => (
                              <IoMdStar size={"18px"} key={i} />
                            ))}
                        </p>
                        <p className="text-slate-500 mt-1 text-[14px]">
                          {e?.documents?.feedback}
                        </p>
                      </section>
                    ))}
                    <section className="bg-white w-full py-2 space-y-3">
                      <InputField
                        label="Rate this product 1 to 5"
                        type="number"
                        onChange={(event) =>
                          setSelectedRating(event.target.value)
                        }
                      />
                      <InputField
                        label="Leave a feedback"
                        onChange={(event) =>
                          setSelectedFeedback(event.target.value)
                        }
                      />
                      <Button onClick={addReview}>Submit</Button>
                    </section>
                  </div>
                </DialogBox>
              </div>
              <p className="mt-6 text-slate-600">
                Selected Pack Size :{" "}
                <span className="font-semibold text-slate-800">
                  {groups[productType]?.title}
                </span>
              </p>
              <div className="flex gap-3 overflow-auto mt-3">
                {groups &&
                  groups.map((e, i) => (
                    <section
                      className={`w-[8rem] rounded-lg border cursor-pointer ${
                        e?.title == groups[productType]?.title
                          ? "border-blue-500 border-[1.7px]"
                          : "border-slate-300"
                      }`}
                      key={i}
                      onClick={() => setproductType(i)}
                    >
                      <div className="w-full text-left px-3 py-[6px] border-b-[1px] border-slate-300">
                        {e.title}
                      </div>
                      <div className="px-3 py-2 space-y-[1px]">
                        <p className="text-[16px] font-medium font-serif">
                          ₹{e.price}
                        </p>
                        {e.isAvailable === true ? (
                          <p className="text-[14px] font-serif text-green-600 font-medium">
                            In Stock
                          </p>
                        ) : (
                          <p className="text-[14px] font-serif text-red-500 font-medium">
                            Out of Stock
                          </p>
                        )}
                      </div>
                    </section>
                  ))}
              </div>
              <div className="mt-5 space-y-1">
                <section>
                  <p className="font-semibold">Manufacturer & Marketer</p>
                  <Link className="text-blue-500 underline text-[14px]">
                    {productData?.mkt}
                  </Link>
                </section>
                <section>
                  <p className="font-semibold">Consume Type</p>
                  <p className="text-[14px] text-slate-600">
                    {productData?.consumeType}
                  </p>
                </section>
                {productData?.policy && (
                  <section>
                    <p className="font-semibold">Return Policy</p>
                    <Link className="text-blue-500 underline text-[14px]">
                      {productData?.policy}
                    </Link>
                  </section>
                )}
                <section>
                  <p className="font-semibold">Expires On or After</p>
                  <p className="text-[14px] text-slate-600">
                    {groups[productType]?.expiryDate}
                  </p>
                </section>
              </div>
            </div>
            <div className="w-[28vw] hidden lg:flex">
              <CardComponent width="20rem">
                <div className="border-b-[1px] w-full px-3 text-center py-2">
                  <p className="space-x-3">
                    {" "}
                    <span className="text-[30px] font-bold ml-3">
                      ₹{groups[productType]?.price}
                    </span>
                    <span className="text-[12px] text-slate-500">
                      <strike>MRP: {groups[productType]?.price}</strike>
                    </span>
                  </p>
                  <span className="text-[13px] text-green-600 font-semibold">
                    {productData?.discount}% off
                  </span>
                </div>
                <div className="w-full px-3 py-3 border-b-[1px] text-center">
                  <p className="text-[13px]">
                    {groups[productType]?.title} •{" "}
                    <span className="text-slate-500">
                      {"(Inclusive all taxes)"}
                    </span>
                  </p>
                </div>
                <div className="py-5 px-3 w-full border-b-[1px] flex flex-col justify-center items-center gap-2">
                  <PincodeCompo />
                </div>
                <div className="py-5 px-3 w-full border-b-[1px] flex flex-col justify-center items-center gap-3">
                  {groups[productType]?.isAvailable === true ? (
                    <AddToCart
                      productId={productData?._id}
                      groupIndex={productType}
                      price={groups[productType]?.price}
                      type={productData?.type}
                    />
                  ) : (
                    <AddToCart
                      disabled
                      productId={productData?._id}
                      groupIndex={productType}
                      price={groups[productType]?.price}
                    />
                  )}
                </div>
              </CardComponent>
            </div>
          </div>
          <div className="w-[58vw] mt-5">
            <AccrodianBox dataArray={productDetails} />
            <div className="w-full mt-3">
              <Heading2 className="text-[27px] font-semibold">
                Safety Advice
              </Heading2>
              <div className="w-full">
                <div className="mt-3">{productData?.safetyAdvice}</div>
                <div className="w-full border-slate-400 border mt-10 rounded-2xl p-4 space-y-2">
                  <section>
                    <p className="text-[16px] font-semibold">
                      Country of Origin
                    </p>
                    <p className="text-slate-500 text-[15px]">
                      {productData?.countryOfOrigin}
                    </p>
                  </section>
                  <section>
                    <p className="text-[16px] font-semibold">
                      Manufacturer/Marketer address
                    </p>
                    <p className="text-slate-500 text-[15px]">
                      19, Bishop Gardens, Raja Annamalaipuram, Chennai - 600028,
                      Tamil Nadu, India
                    </p>
                  </section>
                  <section>
                    <p className="text-[16px] font-semibold">Other Info</p>
                    <p className="text-slate-500 text-[15px]">HBI0032</p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90vw] flex flex-col lg:hidden justify-between items-center gap-2">
        <section className="w-full mb-5">
          <BredcrumbBox />
          <p className="text-[13px] font-semibold text-green-600 mt-3">
            19 people bought in last 90 days
          </p>
        </section>
        <Heading className={"text-[27px] font-semibold"}>
          {productData?.title}
        </Heading>
        <div className="flex items-center gap-2">
          <p className="bg-green-600 w-[3rem] rounded-md text-center flex items- justify-center px-2 text-white text-[13px] py-[1px] font-medium">
            {Math.round(Number(productData?.avgRating) * 10) / 10}{" "}
            <IoMdStar size={"18px"} />
          </p>
          <span>•</span>
          <DialogBox
            title={`${
              review[0]?.totalCount != undefined
                ? `${review[0]?.totalCount}`
                : "0"
            } People Reviewed`}
            buttonClass={"h-auto p-0 m-0 border-none text-slate-500"}
          >
            <div className="flex flex-col gap-3">
              {review.map((e, i) => (
                <section className="bg-green-50 px-5 py-3 rounded-lg" key={i}>
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-[15px]">
                      {e?.documents?.userName?.slice(0, 20) + "..."}
                    </p>
                    {e?.documents?.userId === userData?._id ? (
                      <Button
                        variant="ghost"
                        onClick={() => deleteReview(e?.documents?._id)}
                      >
                        <MdDelete className="text-[20px] text-red-500" />
                      </Button>
                    ) : null}
                  </div>
                  <p className="flex items-center text-green-600 text-[14px] mt-1 mb-1 font-medium">
                    {Array(e?.documents?.rating)
                      .fill(0)
                      .map((_, i) => (
                        <IoMdStar size={"18px"} key={i} />
                      ))}
                  </p>
                  <p className="text-slate-500 mt-1 text-[14px]">
                    {e?.documents?.feedback}
                  </p>
                </section>
              ))}
              <section className="bg-white w-full py-2 space-y-3">
                <InputField
                  label="Rate this product 1 to 5"
                  type="number"
                  onChange={(event) => setSelectedRating(event.target.value)}
                />
                <InputField
                  label="Leave a feedback"
                  onChange={(event) => setSelectedFeedback(event.target.value)}
                />
                <Button onClick={addReview}>Submit</Button>
              </section>
            </div>
          </DialogBox>
        </div>
        <div className="w-[90vw] flex flex-col gap-5 justify-center items-center mt-10">
          <div>
            <img
              src={`${SERVER_URL}/api/v1/fetch/image/${productImg}`}
              className="w-[10rem]"
              alt=""
            />
          </div>
          <div className="flex gap-2">
            {productImgArray &&
              productImgArray.map((e, i) => (
                <Button
                  className={`w-[4rem] h-[4rem] bg-white border hover:bg-white ${
                    productImg === e ? "border-green-900" : ""
                  }`}
                  key={i}
                  onClick={() => setProductImg(e)}
                >
                  <img
                    src={`${SERVER_URL}/api/v1/fetch/image/${e}`}
                    className="w-full object-cover"
                    alt=""
                  />
                </Button>
              ))}
          </div>
        </div>
        <div className="w-[90vw] flex flex-col justify-center items-center">
          <p className="mt-6 text-slate-600 text-left w-full">
            Selected Pack Size :{" "}
            <span className="font-semibold text-slate-800">90</span>
          </p>
          <div className="flex gap-3 justify-start items-center overflow-auto mt-3 w-[90vw]">
            {groups &&
              groups.map((e, i) => (
                <section
                  style={{scrollbarWidth: "none"}}
                  className={`w-[8rem] rounded-lg border cursor-pointer flex-shrink-0 ${
                    e?.title == groups[productType]?.title
                      ? "border-blue-500 border-[1.7px]"
                      : "border-slate-300"
                  }`}
                  key={i}
                  onClick={() => setproductType(i)}
                >
                  <div className="w-full h-[2rem] text-left px-3 py-[6px] border-b-[1px] border-slate-300">
                    <p className="truncate">{e.title}</p>
                  </div>
                  <div className="px-3 py-2 space-y-[1px]">
                    <p className="text-[16px] font-medium font-serif">
                      ₹{e.price}
                    </p>
                    {e.isAvailable === true ? (
                      <p className="text-[14px] font-serif text-green-600 font-medium">
                        In Stock
                      </p>
                    ) : (
                      <p className="text-[14px] font-serif text-red-500 font-medium">
                        Out of Stock
                      </p>
                    )}
                  </div>
                </section>
              ))}
          </div>
          <div className="flex flex-col gap-2 justify-center items-center mt-3 w-[90vw]">
            <p className="space-x-3 text-slate-500 border-b-[1px] pb-3 w-full">
              PRICE:{" "}
              <span className="text-slate-800 font-semibold">
                ₹{groups[productType]?.price}
              </span>
              <span className="text-[13px]">
                <strike>MRP: ₹{groups[productType]?.mrp}</strike>
              </span>
              <span className="text-green-600 text-[13px] font-semibold">
                {productData?.discount}% off
              </span>
            </p>
            <p className="space-x-3 text-slate-500 text-[12px] pb-3 w-full">
              <span>{groups[productType]?.title}</span> <span>•</span>
              <span className="text-slate-500">{"(Inclusive all taxes)"}</span>
            </p>
            <div className="py-5 px-3 w-full border-b-[1px] flex flex-col justify-start items-start border-[1px] rounded-2xl gap-2">
              <PincodeCompo />
            </div>
            <div className="mt-5 text-left w-full space-y-1">
              <section>
                <p className="font-semibold text-[13px]">
                  Manufacturer & Marketer
                </p>
                <Link className="text-blue-500 underline text-[13px]">
                  {productData?.mkt}
                </Link>
              </section>
              <section>
                <p className="font-semibold text-[13px]">Consume Type</p>
                <p className="text-[13px] text-slate-600">
                  {productData?.consumeType}
                </p>
              </section>
              {productData?.policy && (
                <section>
                  <p className="font-semibold">Return Policy</p>
                  <Link className="text-blue-500 underline text-[14px]">
                    {productData?.policy}
                  </Link>
                </section>
              )}
              <section>
                <p className="font-semibold text-[13px]">Expires On or After</p>
                <p className="text-[13px] text-slate-600">
                  {groups[productType]?.expiryDate}
                </p>
              </section>
            </div>
            <div className="w-full fixed bottom-0 bg-white">
              <div className="py-5 px-3 w-full border-b-[1px] flex flex-col justify-center items-center gap-3">
                  {groups[productType]?.isAvailable === true ? (
                    <AddToCart
                      productId={productData?._id}
                      groupIndex={productType}
                      price={groups[productType]?.price}
                    />
                  ) : (
                    <AddToCart
                      disabled
                      productId={productData?._id}
                      groupIndex={productType}
                      price={groups[productType]?.price}
                    />
                  )}
                </div>
            </div>
            <div className="w-[90vw]">
              <AccrodianBox dataArray={productDetails} />
              <div className="w-full mt-3">
                <Heading2 className="text-[20px] font-semibold">
                  Safety Advice
                </Heading2>
                <div className="w-full">
                  <div className="mt-3">{productData?.safetyAdvice}</div>
                  <div className="w-full border-slate-400 border mt-10 rounded-2xl p-4 space-y-2">
                    <section>
                      <p className="text-[16px] font-semibold">
                        Country of Origin
                      </p>
                      <p className="text-slate-500 text-[15px]">
                        {productData?.countryOfOrigin}
                      </p>
                    </section>
                    <section>
                      <p className="text-[16px] font-semibold">
                        Manufacturer/Marketer address
                      </p>
                      <p className="text-slate-500 text-[15px]">
                        19, Bishop Gardens, Raja Annamalaipuram, Chennai -
                        600028, Tamil Nadu, India
                      </p>
                    </section>
                    <section>
                      <p className="text-[16px] font-semibold">Other Info</p>
                      <p className="text-slate-500 text-[15px]">HBI0032</p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90vw] 2xl:w-[95rem] mt-10">
        <Heading2>You Might Like</Heading2>
        <GroupCarousel dataArray={bestsellers} />
      </div>
    </div>
  );
}

export default Product;
