import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { SiTicktick } from "react-icons/si";
import { Link, useParams, useSearchParams } from "react-router-dom";

function LabOrder() {
  const [labData, setLabData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  async function fetchLabData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/payment/my-tests`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setLabData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Orders fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLabData();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[90vw] md:w-[50rem] flex justify-center items-center flex-col border rounded-lg">
        {labData?.map((e, i) => (
          <Link className="w-full" to={`/ordered-tests/${e?._id}`} key={i}>
            <section className="flex justify-between items-start w-full border-b-[1px] py-3 px-5 hover:bg-slate-50">
              <div>
                <span className="text-[10px] sm:text-[12px] text-slate-500 bg-slate-200 px-2 rounded-xl">
                  {e?.date?.split("T")[0]}
                </span>
                <p className="font-medium mt-1 text-[13px] sm:text-[15px]">
                  {" "}
                  {e?.tests?.length > 1
                    ? `${e?.tests?.length} tests booked`
                    : `${e?.tests?.length} test booked`}
                </p>
              </div>
              <div>
                <p>
                  {e?.testStatus === "completed" ? (
                    <span className="text-green-600 text-[13px] sm:text-[15px]">
                      Completed
                    </span>
                  ) : (
                    <span className="text-red-600 text-[13px] sm:text-[15px]">
                      {e?.testStatus === "initiated" ? "Booked" : e?.testStatus?.charAt(0)?.toUpperCase() + e?.testStatus?.slice(1)}
                    </span>
                  )}
                </p>
                <p className="flex items-center gap-3 mt-1 text-[13px] sm:text-[15px] text-slate-500 justify-end">
                  See More <IoIosArrowForward />
                </p>
              </div>
            </section>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default LabOrder;
