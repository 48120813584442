import DialogBox from "@/components/DialogBox";
import Heading from "@/components/Heading";
import Heading2 from "@/components/Heading2";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import { Link } from "react-router-dom";

function EditDoc() {
  const productId = useSelector((state) => state.productData?.productData);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [brands, setBrands] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { register: infoRegister, handleSubmit: handleInfoSubmit } = useForm();
  const { register: consultRegister, handleSubmit: handleConsultSubmit } =
    useForm();
  const { register: dateRegister, handleSubmit: handleDateSubmit } = useForm();
  const [reload, setReload] = useState(false)
  const [productData, setProductData] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogOpen2, setDialogOpen2] = useState(false)

  async function updateInfo(data) {
    if(productId?.length > 0) {
      data = {...data, id: productId}
    }
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addConsultType(data) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
      return null;
    }
    data = { consultType: data, doctorId: productId };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/consult-type/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setDialogOpen(false)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchCatagories() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/catagory`,
        method: "get",
        withCredentials: true,
      });
      setCatagories(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDoctor() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/get/doctor/${productId}`,
        method: "get",
        withCredentials: true,
      });
      setProductData(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    fetchDoctor()
  },[reload])

  //console.log(productData);

  async function delConsult(consultData) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    const data = {
      doctorId: productId,
      consultType: { place: consultData.place },
    };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/consult-type/delete`,
        method: "delete",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delDate(dateId) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    const data = { doctorId: productId, dateAndTime: { _id: dateId } };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/date-time/delete`,
        method: "delete",
        data: data,
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function addDate(data) {
    if (!productId) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    const dateAndTime = {
      weekday: data?.weekday,
      times: [data?.time1, data?.time2, data?.time3, data?.time4, data?.time5],
    };
    console.log(dateAndTime);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/date-time/add`,
        method: "post",
        data: {
          doctorId: productId,
          dateAndTime,
        },
        withCredentials: true,
      });
      console.log(res.data);
      setDialogOpen2(false)
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
    } finally {
      setLoading(false);
    }
  }

  function handleFileChange(event) {
    const image = event.target.files[0];
    setFile(image);
  }

  async function uploadImage() {
    if (file?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select a file");
    }
    const formData = new FormData();

    formData.append("doctorImg", file);
    formData.append("id", productId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/doctors/add`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      console.log(res.data);
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const userData = useSelector((state) => state.auth?.userData);

  if (!isAuthenticated) {
    return <Heading>You are not logged in</Heading>;
  }

  if (userData?.isAdmin === false) {
    return <Heading>You are not admin</Heading>;
  }

  useEffect(() => {
    fetchCatagories();
  }, []);

  return (
    <div className="p-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full text-center">
        <Heading2>Add or Update Doctor</Heading2>
      </div>
      <div className="w-full">
        <form
          className="w-full flex flex-col gap-5 justify-center items-center mt-5"
          onSubmit={handleInfoSubmit(updateInfo)}
        >
          <div className="w-full flex flex-wrap gap-5 justify-center items-end mt-5">
            <InputField
              label="Name"
              {...infoRegister("name")}
              defaultValue={productData?.name}
            />
            <div className="flex flex-col gap-2">
              <Label>
                Select designation{" "}
                {productData?.brand && (
                  <span className="text-slate-500">
                    (Prev: {productData?.designation})
                  </span>
                )}
              </Label>
              <select
                className="w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                {...infoRegister("designation")}
              >
                {catagories?.map((e, i) => (
                  <option value={e?.name} key={i}>
                    {e?.name}
                  </option>
                ))}
              </select>
            </div>
            <InputField
              label="Place"
              {...infoRegister("place")}
              defaultValue={productData?.place}
            />
            <InputField
              label="Workplace"
              {...infoRegister("workplace")}
              defaultValue={productData?.workplace}
            />
            <InputField
              label="Education"
              {...infoRegister("education")}
              defaultValue={productData?.education}
            />
            <InputField
              label="Registration Number"
              {...infoRegister("regNo")}
              defaultValue={productData?.regNo}
            />
          </div>
          <Button>Save</Button>
        </form>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Consult Type
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            {productData?.consultType?.map((j, i) => (
              <div className="border px-3 py-3 rounded-md" key={i}>
                <p>Place : {j?.place}</p>
                <p>Mode : {j?.consultMode}</p>
                <p>Charge : {j?.charge}</p>
                <Button
                  variant="destructive"
                  className="mt-5 w-full text-[13px] h-[2rem]"
                  onClick={() => delConsult(j)}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <DialogBox title={"Add Group"} dialogOpen={dialogOpen} onOpenChange={setDialogOpen}>
            <form
              className="space-y-3"
              onSubmit={handleConsultSubmit(addConsultType)}
            >
              <InputField label="Place" {...consultRegister("place")} />
              <div className="flex flex-col gap-2">
                <Label>
                  Select Consult Mode{" "}
                  {productData?.consultMode && (
                    <span className="text-slate-500">
                      (Prev: {productData?.consultMode})
                    </span>
                  )}
                </Label>
                <select
                  className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                  defaultValue={""}
                  {...consultRegister("consultMode", {required: true})}
                >
                  <option value={"online"}>Online</option>
                  <option value={"offline"}>Offline</option>
                </select>
              </div>
              <InputField label="Charge" {...consultRegister("charge")} />
              <Button>Save</Button>
            </form>
          </DialogBox>
        </div>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Date and Time
          </p>
          <div className="w-full flex justify-start items-center mt-5 gap-5 mb-5">
            {productData?.availableDates?.map((j, i) => (
              <div className="border px-3 py-3 rounded-md" key={i}>
                <p className="font-semibold">{days[j?.weekday]}</p>
                <div className="flex gap-2 flex-wrap mt-2">
                  {j?.times?.map(
                    (k, i) =>
                      k?.length > 0 && <div className="border p-2">{k}</div>
                  )}
                </div>
                <Button
                  variant="destructive"
                  className="mt-2"
                  onClick={() => delDate(j?._id)}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <DialogBox title={"Add Date"} dialogOpen={dialogOpen2} onOpenChange={setDialogOpen2}>
            <form className="space-y-3" onSubmit={handleDateSubmit(addDate)}>
              <div className="flex flex-col gap-2">
                <Label>Select Day</Label>
                <select
                  className="max-w-[24rem] h-[2.5rem] p-[0.37rem] rounded-md bg-transparent border"
                  {...dateRegister("weekday")}
                >
                  {days?.map((e, i) => (
                    <option value={i} key={i}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
              <InputField
                label="Time 1"
                placeholder="E.g, 9:00 AM"
                {...dateRegister("time1")}
              />
              <InputField
                label="Time 2"
                placeholder="E.g, 9:00 AM"
                {...dateRegister("time2")}
              />
              <InputField
                label="Time 3"
                placeholder="E.g, 9:00 AM"
                {...dateRegister("time3")}
              />
              <InputField
                label="Time 4"
                placeholder="E.g, 9:00 AM"
                {...dateRegister("time4")}
              />
              <InputField
                label="Time 5"
                placeholder="E.g, 9:00 AM"
                {...dateRegister("time5")}
              />
              <Button>Save</Button>
            </form>
          </DialogBox>
        </div>
        <div className="w-full flex justify-center items-center flex-col mt-5">
          <p className="bg-green-500 w-full text-center py-2 text-[17px] font-bold text-white">
            Pictures
          </p>
          <div className="w-full flex justify-center items-center mt-5 gap-5 mb-5">
            <img
              src={`${SERVER_URL}/api/v1/fetch/image/${productData?.picture}`}
              className="w-[10rem]"
            />
          </div>
          <div className="flex items-end gap-2">
            <InputField
              label={<p>Pictures</p>}
              type="file"
              onChange={(e) => handleFileChange(e)}
            />
            <Button onClick={uploadImage}>Upload</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDoc;
